import { Component, OnInit, ViewChild } from '@angular/core';
import { FetchBarcodeListItemsAction } from '../../../barcode/actions/fetch-barcode-list-items.action';
import { Select, Store } from '@ngxs/store';
import { BarcodeState } from '../../../barcode/barcode.state';
import { Observable } from 'rxjs';
import {
  BarcodeList,
  Terminal,
  VendingMachine,
  VendingMachineSlotCreateInput,
} from '../../../../../../../../common/interfaces/prisma.binding';
import {
  CdkDrag,
  CdkDragDrop,
  copyArrayItem,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { FetchTerminalAction } from '../../../terminal/actions/fetch-terminal.action';
import { TerminalState } from '../../../terminal/terminal.state';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CreateVendingMachineMutation } from '../../mutations/create-vending-machine.mutation';
import { NGXLogger } from 'ngx-logger';
import { FetchVendingMachinesAction } from '../../actions/fetch-vending-machines.action';
import { ActivatedRoute, Router } from '@angular/router';
import { VendingMachinesState } from '../../vending-machines.state';
import { UpdateVendingMachineMutation } from '../../mutations/update-vending-machine.mutation';
import { DeleteVendingMachineSlotsMutation } from '../../mutations/delete-vending-machine-slots.mutation';

@Component({
  selector: 'tt-vending-machines-detail',
  templateUrl: './vending-machines-detail.component.html',
  styleUrls: ['./vending-machines-detail.component.scss'],
})
export class VendingMachinesDetailComponent implements OnInit {
  @Select(BarcodeState.barcodeListItems) barcodeListItems$: Observable<
    BarcodeList[]
  >;
  @Select(TerminalState.terminals) terminalList$: Observable<Terminal[]>;
  @Select(VendingMachinesState.vendingMachines)
  vendingMachines$: Observable<VendingMachine[]>;
  connectedTerminalID: string;
  terminals: Terminal[] = [];
  productsToSale: BarcodeList[] = [];
  productsInMachine: Partial<BarcodeList>[] = [];
  relaysInSlots = [];
  slotDisabled = [];
  vendingMachineForm: UntypedFormGroup;
  emptySwitchArray: number[] = [];
  ammountOfProductsArray: number[] = [];
  private id: string;
  currentVendingMachine: VendingMachine;
  vendingMachines: VendingMachine[];
  editMode = false;

  constructor(
    private store: Store,
    private formBuilder: UntypedFormBuilder,
    private createVendingMachineMutation: CreateVendingMachineMutation,
    private updateVendingMachineMutation: UpdateVendingMachineMutation,
    private deleteVendingMachineSlotsMutation: DeleteVendingMachineSlotsMutation,
    private logger: NGXLogger,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.terminalList$.subscribe((terminals) => (this.terminals = terminals));
    this.vendingMachineForm = this.formBuilder.group({
      name: ['', Validators.required],
      optionalWarnPin: [],
      tempSensorInternalPin: [],
      tempSensorExternalPin: [],
    });
    this.store
      .dispatch([
        new FetchVendingMachinesAction(),
        new FetchBarcodeListItemsAction(),
        new FetchTerminalAction(),
      ])
      .subscribe(() => {
        this.route.params.subscribe((params) => {
          if (params['id']) {
            this.editMode = true;
            this.id = params['id'];
            this.populateForm();
          }
        });
      });

    this.barcodeListItems$.subscribe((barcodeListItems) => {
      this.productsToSale = barcodeListItems.sort((a, b) => {
        if (a.data.toLowerCase() < b.data.toLowerCase()) {
          return -1;
        }
        if (a.data.toLowerCase() > b.data.toLowerCase()) {
          return 1;
        }
      });
    });
  }

  drop(event: CdkDragDrop<BarcodeList[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      moveItemInArray(
        this.relaysInSlots,
        event.previousIndex,
        event.currentIndex,
      );
      moveItemInArray(
        this.ammountOfProductsArray,
        event.previousIndex,
        event.currentIndex,
      );
      moveItemInArray(
        this.emptySwitchArray,
        event.previousIndex,
        event.currentIndex,
      );
    } else {
      this.ammountOfProductsArray = this.insert(
        this.ammountOfProductsArray,
        event.currentIndex,
        0,
      );
      this.emptySwitchArray = this.insert(
        this.emptySwitchArray,
        event.currentIndex,
        0,
      );
      this.relaysInSlots = this.insert(
        this.relaysInSlots,
        event.currentIndex,
        undefined,
      );
      copyArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  checkIfProduct(item: CdkDrag<any>) {
    return item.data.__typename === 'BarcodeList';
  }

  checkIfAlreadyInList(item: CdkDrag<any>) {
    return false;
  }

  getTerminalById(terminalId: string): Terminal {
    return this.terminals.find((terminal) => terminal.id === terminalId);
  }

  /**
   * If the Terminal selections changes we delete the selected relais
   */
  terminalChange() {
    console.log(this.connectedTerminalID);
    this.relaysInSlots = [];
  }

  deleteSlot(item: number) {
    this.productsInMachine.splice(item, 1);
    this.relaysInSlots.splice(item, 1);
    this.ammountOfProductsArray.splice(item, 1);
    this.emptySwitchArray.splice(item, 1);
  }

  saveMachine() {
    const slots = this.buildSlots();
    this.createVendingMachineMutation
      .mutate({
        ...this.vendingMachineForm.value,
        terminalId: this.connectedTerminalID,
        slots,
      })
      .subscribe(
        ({ data }) => {
          this.logger.log('got create vending machine', data);
          // refresh store
          this.store.dispatch(new FetchVendingMachinesAction());
          this.router.navigate([
            `/admin/vending-machines/edit/${data['createVendingMachine'].id}`,
          ]);
        },
        (error) => {
          error.graphQLErrors.map(({ message }, i) =>
            // proper error handling
            this.logger.error(message, i),
          );
        },
      );
  }

  async updateMachine() {
    const slotIds = this.currentVendingMachine.slots.map(
      (vendingMachineSlot) => vendingMachineSlot.id,
    );
    await this.deleteVendingMachineSlotsMutation
      .mutate({ ids: slotIds })
      .toPromise();

    this.updateVendingMachineMutation
      .mutate({
        id: this.id,
        ...this.vendingMachineForm.value,
        terminalId: this.connectedTerminalID,
        slots: this.buildSlots(),
      })
      .subscribe(
        ({ data }) => {
          this.logger.log('got update vending machine', data);
          this.productsInMachine = [];
          // refresh store
          this.store.dispatch(new FetchVendingMachinesAction());
        },
        (error) => {
          error.graphQLErrors.map(({ message }, i) =>
            // proper error handling
            this.logger.error(message, i),
          );
        },
      );
  }

  private buildSlots() {
    const slotList: VendingMachineSlotCreateInput[] = [];
    this.productsInMachine.forEach((product, index) => {
      slotList.push({
        ammountOfProducts: this.ammountOfProductsArray[index],
        slotPosition: index + 1, //
        disabled: this.slotDisabled[index],
        internalIsEmptyPin: this.emptySwitchArray[index],
        relay: { connect: { id: this.relaysInSlots[index] } },
        product: { connect: { id: product.id } },
      });
    });
    return slotList;
  }

  populateForm() {
    this.vendingMachines$.subscribe((vendingMachines) => {
      this.vendingMachines = vendingMachines;
      this.currentVendingMachine = this.vendingMachines.find(
        (vendingMachine) => vendingMachine.id === this.id,
      );
      this.vendingMachineForm.patchValue({ ...this.currentVendingMachine });
      this.connectedTerminalID = this.currentVendingMachine.terminal.id;
      this.currentVendingMachine.slots.forEach((slot, index) => {
        this.productsInMachine.push(slot.product);
        this.emptySwitchArray[index] = slot.internalIsEmptyPin;
        this.ammountOfProductsArray[index] = slot.ammountOfProducts;
        this.relaysInSlots[index] = slot.relay.id;
        this.slotDisabled[index] = slot.disabled;
      });
    });
  }

  insert = (arr, index, newItem) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index),
  ];
}
