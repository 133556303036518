import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { TimeTrackingComponent } from './components/time-tracking/time-tracking.component';
import { WorkHoursComponent } from './components/work-hours/work-hours.component';

@NgModule({
    declarations: [TimeTrackingComponent, WorkHoursComponent],
    exports: [],
    imports: [CommonModule, SharedModule]
})
export class TimeTrackingModule {}
