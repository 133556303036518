import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurchasesComponent } from './components/purchases/purchases.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [PurchasesComponent],
  imports: [CommonModule, SharedModule],
})
export class PurchasesModule {}
