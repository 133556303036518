import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';
import { Terminal } from '../../../../../../../common/interfaces/prisma.binding';
import { HassWebhook } from '../../../../../../../common/interfaces/hassWebhook.bindings';

export interface Response {
  hassWebhooks: HassWebhook[];
}

@Injectable({
  providedIn: 'root',
})
export class AllHassWebhooks extends Query<Response> {
  document = gql`
    query getHassWebhooks {
      getHassWebhooks {
        id
        alias
        webhook_id
        description
      }
    }
  `;
}
