import { Routes } from '@angular/router';
import { AdminGuard } from '../../shared/guards/admin.guard';
import { ToolTimeSettingsComponent } from './components/tool-time-settings.component';
import { PowerChunksComponent } from './components/power-chunks/power-chunks.component';

export const TOOL_TIME_SETTINGS_ROUTES: Routes = [
  {
    path: 'admin/settings',
    component: ToolTimeSettingsComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'admin/settings/powerchunks',
    component: PowerChunksComponent,
    canActivate: [AdminGuard],
  },
];
