import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { VendingMachinesDetailComponent } from './components/vending-machines-detail/vending-machines-detail.component';
import { VendingMachinesOverviewComponent } from './components/vending-machines-overview/vending-machines-overview.component';

@NgModule({
  declarations: [
    VendingMachinesDetailComponent,
    VendingMachinesOverviewComponent,
  ],
  imports: [CommonModule, SharedModule],
})
export class VendingMachinesModule {}
