import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class UpdateVendingMachineMutation extends Mutation {
  document = gql`
    mutation updateVendingMachine(
      $id: ID!
      $name: String!
      $terminalId: ID
      $tempSensorInternalPin: Int
      $tempSensorExternalPin: Int
      $optionalWarnPin: Int
      $slots: [VendingMachineSlotCreateInput!]
    ) {
      updateVendingMachine(
        where: { id: $id }
        data: {
          name: $name
          tempSensorExternalPin: $tempSensorExternalPin
          tempSensorInternalPin: $tempSensorInternalPin
          optionalWarnPin: $optionalWarnPin
          terminal: { connect: { id: $terminalId } }
          slots: { create: $slots }
        }
      ) {
        id
      }
    }
  `;
}
