import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';
import { GPIODevice } from '../../../../../../../common/interfaces/prisma.binding';

export interface Response {
  allGPIODevices: GPIODevice[];
}

@Injectable({
  providedIn: 'root',
})
export class AllGPIODevicesQuery extends Query<Response> {
  document = gql`
    query allgPIODevices {
      gPIODevices {
        id
        connectedRelay {
          id
        }
        description
        deviceID
        minRSSI
        isBeacon
        lastSeen
        macAddress
        hasRFIDReader
        hasDisplay
        lastIpAddress
        hasBarcodeReader
        hasDropBusDevice
        hasModBusDevice
      }
    }
  `;
}
