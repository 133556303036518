import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthState } from '../../shared/state/auth.state';
import { User } from '../../../../../../common/interfaces/prisma.binding';
import { Store } from '@ngxs/store';
import { environment } from '../../../../../../common/angular/environments/environment';

@Injectable()
export class RelayService {
  constructor(
    private readonly http: HttpClient,
    private readonly store: Store,
  ) {}

  testTriggerRelais(relayId: string) {
    const currentUser = this.store.selectSnapshot(
      AuthState.userDetails,
    ) as User;

    this.http
      .post(`${environment.imageBackend}/rfid/toggledevice-backend`, {
        socketMessage: {
          userId: currentUser.id,
          relayId,
        },
      })
      .toPromise();
  }
}
