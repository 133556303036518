import { Query } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { User } from '../../../../../../../common/interfaces/prisma.binding';
import gql from 'graphql-tag';

export interface Response {
  allUsers: User[];
}

@Injectable({
  providedIn: 'root',
})
export class AllUsersQuery extends Query<Response> {
  document = gql`
    query allUsers {
      users {
        id
        currency
        credit
        email
        firstName
        lastName
        keycloakID
        username
        invoiceID
        invoiceIDPrivate
        isTeam
        rfidNumber
      }
    }
  `;
}
