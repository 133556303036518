import { Component, Inject, OnInit } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';
import { environment } from '../../../../../../../../common/angular/environments/environment';

@Component({
  selector: 'tt-billomat-snackbar',
  templateUrl: './billomat-snackbar.component.html',
  styleUrls: ['./billomat-snackbar.component.scss'],
})
export class BillomatSnackbarComponent implements OnInit {
  environment;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private _snackRef: MatSnackBarRef<BillomatSnackbarComponent>,
  ) {}

  ngOnInit(): void {
    this.environment = environment;
  }
  closeSnackbar() {
    this._snackRef.dismiss();
  }
}
