import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class DeleteManyBarcodeItemsMutation extends Mutation {
  document = gql`
    mutation updateManyBarcodeItems($id: [ID!]) {
      deleteManyBarcodeItems(where: { id_in: $id }) {
        count
      }
    }
  `;
}
