import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';
import { TimeEntry } from '../../../../../../../common/interfaces/prisma.binding';

export interface Response {
  timeEntries: TimeEntry[];
}

@Injectable({
  providedIn: 'root',
})
export class AllTimeEntriesQuery extends Query<Response> {
  document = gql`
    query allTimeEntries {
      timeEntries(
        where: { OR: [{ isHourRecording: false }, { isHourRecording: null }] }
      ) {
        id
        createdAt
        bookedTime
        date
        description
        isApproved
        project {
          projectNumber
          id
          isGlobal
        }
        user {
          id
          username
          firstName
          lastName
        }
      }
    }
  `;
}
