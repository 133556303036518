import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subject } from 'rxjs';
import {
  Container,
  ElectricMeter,
  ElectricMeterCreateOneWithoutContainerInput,
  Team,
  TeamCreateOneWithoutContainerInput,
  User,
  UserCreateOneWithoutContainerInput,
} from '../../../../../../../../common/interfaces/prisma.binding';
import { FetchContainerAction } from '../../../container/actions/fetch-container.action';
import { FetchElectricMetersAction } from '../../../electric-meters/actions/fetch-electric-meters.action';
import { ElectricMeterState } from '../../../electric-meters/electric-meter.state';
import { FetchTeamsAction } from '../../../team/actions/fetch-teams.action';
import { TeamState } from '../../../team/team.state';
import { FetchUserAction } from '../../../user/actions/fetch-user.action';
import { UserState } from '../../../user/user.state';
import { ContainerState } from '../../container.state';
import { CreateContainerMutation } from '../../mutations/create-container-mutation';
import { UpdateContainerMutation } from '../../mutations/update-container-mutation';

@Component({
  selector: 'tt-container-detail',
  templateUrl: './container-detail.component.html',
  styleUrls: ['./container-detail.component.scss'],
})
export class ContainerDetailComponent implements OnInit {
  editMode = false;

  containerForm: UntypedFormGroup;
  @Select(UserState.users) users$: Observable<User[]>;
  @Select(ElectricMeterState.electricMeters) electricMeters$: Observable<
    ElectricMeter[]
  >;
  @Select(TeamState.teams) teams$: Observable<Team[]>;
  @Select(ContainerState.containers) containers$: Observable<Container[]>;
  team: TeamCreateOneWithoutContainerInput = {};
  electricMeter: ElectricMeterCreateOneWithoutContainerInput = {};
  user: UserCreateOneWithoutContainerInput = {};
  initiallySelectedTeam$ = new Subject<[any]>();
  initiallySelectedUser$ = new Subject<[any]>();
  initiallySelectedElectricMeter$ = new Subject<[any]>();
  private _id: string;

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private logger: NGXLogger,
    private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: UntypedFormBuilder,
    private createContainerMutation: CreateContainerMutation,
    private updateContainerMutation: UpdateContainerMutation,
  ) {}

  ngOnInit() {
    this.containerForm = this.formBuilder.group({
      internalNumber: ['', Validators.required],
      location: [''],
      description: [''],
      user: [''],
      team: [''],
      electricMeter: [''],
    });

    this.store
      .dispatch([
        new FetchContainerAction(),
        new FetchElectricMetersAction(),
        new FetchUserAction(),
        new FetchTeamsAction(),
      ])
      .subscribe(() => {
        this.route.params.subscribe((params) => {
          if (params['id'] !== undefined) {
            this.editMode = true;
            this._id = params['id'];
            this._populateForm(this._id);
          }
        });
      });
  }

  createContainer() {
    this.createContainerMutation
      .mutate({
        internalNumber: parseInt(
          this.containerForm.value['internalNumber'].toString(),
          10,
        ),
        description: this.containerForm.value['description'],
        location: this.containerForm.value['location'],
        team: this.team,
        electricMeter: this.electricMeter,
        user: this.user,
      })
      .subscribe(
        ({ data }) => {
          this.logger.log('got create container', data);
          // refresh store
          this.store.dispatch(new FetchContainerAction());
          this.router.navigate([
            `/admin/container/edit/${data['createContainer'].id}`,
          ]);
        },
        (error) => {
          error.graphQLErrors.map(({ message }, i) =>
            // proper error handling
            this.logger.error(message, i),
          );
        },
      );
  }

  updateContainer() {
    this.updateContainerMutation
      .mutate({
        id: this._id,
        internalNumber: parseInt(
          this.containerForm.value['internalNumber'].toString(),
          10,
        ),
        description: this.containerForm.value['description'],
        location: this.containerForm.value['location'],
        team: this.team,
        electricMeter: this.electricMeter,
        user: this.user,
      })
      .subscribe(
        ({ data }) => {
          this.logger.log('got update container', data);
          // refresh store
          this.store.dispatch(new FetchContainerAction());
        },
        (error) => {
          error.graphQLErrors.map(({ message }, i) =>
            // proper error handling
            this.logger.error(message, i),
          );
        },
      );
  }

  onTeamSelectionChange(event) {
    if (event.length > 0) {
      this.team = {
        connect: {
          id: event[0].id,
        },
      };
    } else {
      // @ts-ignore
      this.team = { disconnect: true };
    }
  }

  onUserSelectionChange(event) {
    if (event.length > 0) {
      this.user = { connect: { id: (this.user = event[0].id) } };
    } else {
      // @ts-ignore
      this.user = { disconnect: true };
    }
  }

  onElectricMeterSelectionChange(event) {
    if (event.length > 0) {
      this.electricMeter = {
        connect: {
          id: event[0].id,
        },
      };
    } else {
      // @ts-ignore
      this.electricMeter = { disconnect: true };
    }
  }

  private _populateForm(id: string) {
    this.containers$.subscribe((containers) => {
      // @ts-ignore
      const currentContainer: Container = containers.find(
        (container) => container.id === id,
      );
      if (currentContainer) {
        if (currentContainer.user) {
          this.user = { connect: { id: currentContainer.user.id } };
          this.initiallySelectedUser$.next([currentContainer.user]);
        }
        if (currentContainer.team) {
          this.team = { connect: { id: currentContainer.team.id } };
          this.initiallySelectedTeam$.next([currentContainer.team]);
        }
        if (currentContainer.electricMeter) {
          this.electricMeter = {
            connect: { id: currentContainer.electricMeter.id },
          };
          this.initiallySelectedElectricMeter$.next([
            currentContainer.electricMeter,
          ]);
        }
        this.containerForm.patchValue({
          internalNumber: currentContainer.internalNumber,
          location: currentContainer.location,
          description: currentContainer.description,
          user: currentContainer.user,
          team: currentContainer.team,
          electricMeter: currentContainer.electricMeter,
        });
      }
    });
  }
}
