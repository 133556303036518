import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class UpdateManyBarcodeItemsMutation extends Mutation {
  document = gql`
    mutation updateManyBarcodeItems($alreadyBilled: Boolean, $id: [ID!]) {
      updateManyBarcodeItems(
        where: { id_in: $id }
        data: { alreadyBilled: $alreadyBilled }
      ) {
        count
      }
    }
  `;
}
