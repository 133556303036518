import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {InvoicesComponent} from './components/invoices/invoices.component';

@NgModule({
    declarations: [
        InvoicesComponent,
    ],
    exports: [],
    imports: [
        CommonModule,
        SharedModule,
    ]
})
export class InvoicesModule {
}
