import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class CreateUserMutation extends Mutation {
  document = gql`
    mutation createUser(
      $firstName: String!
      $lastName: String!
      $username: String!
      $street: String!
      $zip: String!
      $email: String!
      $city: String!
      $housenumber: String!
      $birthday: DateTime
      $userRoles: UserRoleCreateManyInput!
      $pin: String
      $rfidID: String
      $rfidNumber: Int
      $phone: String
      $givenKeys: String
      $cameOver: String
      $notes: String
      $company: String
      $currency: String
      $invoiceID: String
      $invoiceIDPrivate: String
      $documents: ImageCreateManyInput
      $idCardImages: ImageCreateManyInput
      $liabilityInsuranceImages: ImageCreateManyInput
      $container: ContainerCreateManyWithoutUserInput
      $invoices: ImageCreateManyInput
      $meterMeterReading: MeterReadingCreateManyWithoutUserInput
      $isTeam: Boolean
    ) {
      createUser(
        data: {
          firstName: $firstName
          lastName: $lastName
          street: $street
          username: $username
          zip: $zip
          email: $email
          city: $city
          housenumber: $housenumber
          birthday: $birthday
          userRoles: $userRoles
          pin: $pin
          rfidID: $rfidID
          rfidNumber: $rfidNumber
          phone: $phone
          givenKeys: $givenKeys
          cameOver: $cameOver
          notes: $notes
          company: $company
          currency: $currency
          documents: $documents
          idCardImages: $idCardImages
          invoiceID: $invoiceID
          invoiceIDPrivate: $invoiceIDPrivate
          liabilityInsuranceImages: $liabilityInsuranceImages
          container: $container
          invoices: $invoices
          meterMeterReading: $meterMeterReading
          isTeam: $isTeam
        }
      ) {
        id
        keycloakID
      }
    }
  `;
}
