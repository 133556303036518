import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';
import { LoggingUserTerminalInterface } from '../logging.state';

export interface Response {
  grayLogs: LoggingUserTerminalInterface[];
}

@Injectable({
  providedIn: 'root',
})
export class LogsByTerminalQuery extends Query<Response> {
  document = gql`
    query logs(
      $from: DateTime!
      $to: DateTime!
      $limit: Int
      $terminalId: String
    ) {
      grayLogs(from: $from, to: $to, terminalId: $terminalId, limit: $limit) {
        timestamp
        user {
          id
          username
          firstName
          lastName
        }
        terminal {
          id
          name
        }
      }
    }
  `;
}
