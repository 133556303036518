import { Action, Selector, State, StateContext } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import {
  VendingMachine,
  VendingMachineSlot,
} from '../../../../../../common/interfaces/prisma.binding';
import { FetchVendingMachinesAction } from './actions/fetch-vending-machines.action';
import { AllVendingMachinesQuery } from './queries/all-vending-machines.query';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

export interface VendingMachinesStateModel {
  vendingMachines: VendingMachine[];
  vendingMachineSlots: VendingMachineSlot[];
}

@State<VendingMachinesStateModel>({
  name: 'vendingMachineSlots',
  defaults: {
    vendingMachines: [],
    vendingMachineSlots: [],
  },
})
@Injectable()
export class VendingMachinesState {
  constructor(private allVendingMachinesQuery: AllVendingMachinesQuery) {}

  @Selector()
  static vendingMachines(state: VendingMachinesStateModel) {
    return state.vendingMachines;
  }

  @Selector()
  static vendingMachineSlots(state: VendingMachinesStateModel) {
    return state.vendingMachineSlots;
  }

  @Action(FetchVendingMachinesAction)
  getVendingMachines(ctx: StateContext<VendingMachinesStateModel>) {
    return this.allVendingMachinesQuery
      .fetch(
        {},
        {
          fetchPolicy: 'network-only',
        },
      )
      .pipe(
        tap((data) => {
          const state = ctx.getState();
          console.log(data);
          ctx.setState({
            ...state,
            vendingMachines: data.data.vendingMachines,
          });
        }),
      );
  }
}
