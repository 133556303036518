import { Routes } from '@angular/router';
import { AdminGuard } from '../../shared/guards/admin.guard';
import { TriggerableEntitiesOverviewComponent } from './components/triggerable-entities-overview/triggerable-entities-overview.component';
import { TriggerableEntitiesDetailsComponent } from './components/triggerable-entities-details/triggerable-entities-details.component';
import { TriggerableEntitiesAuthorizationComponent } from './components/triggerable-entities-authorization/triggerable-entities-authorization.component';
import { CanComponentDeactivateGuard } from '../../shared/guards/can-component-deactivate.guard';
import { TriggerableEntitiesLocationsOverviewComponent } from './components/triggerable-entities-locations-overview/triggerable-entities-locations-overview.component';
import { HassWebhookNamesOverviewComponent } from './components/hass-webhook-names-overview/hass-webhook-names-overview.component';

export const TRIGGERABLE_ROUTES: Routes = [
  {
    path: 'admin/triggerable',
    component: TriggerableEntitiesOverviewComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'admin/triggerable/locations',
    component: TriggerableEntitiesLocationsOverviewComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'admin/hass-webhooks',
    component: HassWebhookNamesOverviewComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'admin/triggerable/add',
    component: TriggerableEntitiesDetailsComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'admin/triggerable/edit/:id',
    component: TriggerableEntitiesDetailsComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'admin/triggerable-auth',
    component: TriggerableEntitiesAuthorizationComponent,
    canActivate: [AdminGuard],
    canDeactivate: [CanComponentDeactivateGuard],
  },
];
