import { LayoutModule } from '@angular/cdk/layout';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import {
  ErrorStateMatcher,
  MatNativeDateModule,
  ShowOnDirtyErrorStateMatcher,
} from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import {
  AuthConfig,
  JwksValidationHandler,
  OAuthModule,
  ValidationHandler,
} from 'angular-oauth2-oidc';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { environment } from '../../../common/angular/environments/environment';
import { AppInitService } from './app-init.service';
import { AppRoutingModule } from './app-routing.module';
import { GraphQLModule } from './graphql.module';
import { AreaModule } from './modules/admin/area/area.module';
import { AreaState } from './modules/admin/area/area.state';
import { ContainerModule } from './modules/admin/container/container.module';
import { ContainerState } from './modules/admin/container/container.state';
import { ElectricMeterState } from './modules/admin/electric-meters/electric-meter.state';
import { ElectricMetersModule } from './modules/admin/electric-meters/electric-meters.module';
import { GpioDevicesState } from './modules/admin/gpio-devices/gpio-devices.state';
import { GpioDevicessModule } from './modules/admin/gpio-devices/gpio-devicess.module';
import { RelaysState } from './modules/admin/relays/relays.state';
import { RelayssModule } from './modules/admin/relays/relayss.module';
import { TeamModule } from './modules/admin/team/team.module';
import { TeamState } from './modules/admin/team/team.state';
import { ToolTimeSettingsState } from './modules/admin/tool-time-settings/tool-time-settings.state';
import { UserModule } from './modules/admin/user/user.module';
import { UserState } from './modules/admin/user/user.state';
import { InvoicesModule } from './modules/public/invoices/invoices.module';
import { MyWorkshopModule } from './modules/public/my-workshop/my-workshop.module';
import { MyWorkshopState } from './modules/public/my-workshop/my-workshop.state';
import { PaymentState } from './modules/public/payments/payment.state';
import { PaymentsModule } from './modules/public/payments/payments.module';
import { MainComponent } from './modules/root/components/main/main.component';
import { RootModule } from './modules/root/root.module';
import { SandboxComponent } from './modules/sandbox/sandbox.component';
import { SnackbarComponent } from './modules/shared/components/snackbar/snackbar.component';
import { LoadingStateHttpInterceptor } from './modules/shared/interceptors/loading-state-http.interceptor';
import { TokenHttpInterceptor } from './modules/shared/interceptors/token-http.interceptor';
import { SharedModule } from './modules/shared/shared.module';
import { AuthState } from './modules/shared/state/auth.state';
import { UiState } from './modules/shared/state/ui.state';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarModule,
} from '@angular/material/snack-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TerminalModule } from './modules/admin/terminal/terminal.module';
import { TerminalState } from './modules/admin/terminal/terminal.state';
import { MyAccountModule } from './modules/public/my-account/my-account.module';
import { BarcodeModule } from './modules/admin/barcode/barcode.module';
import { BarcodeState } from './modules/admin/barcode/barcode.state';
import { ProjectAdminModule } from './modules/admin/project-admin/project-admin.module';
import { ProjectAdminState } from './modules/admin/project-admin/project-admin.state';
import { TimeTrackingModule } from './modules/public/time-tracking/time-tracking.module';
import { ProjectsModule } from './modules/public/projects/projects.module';
import { VendingMachinesState } from './modules/admin/VendingMachines/vending-machines.state';
import { VendingMachinesModule } from './modules/admin/VendingMachines/vending-machines.module';
import { PurchasesModule } from './modules/public/purchases/purchases.module';
import { LoggingState } from './modules/admin/logging/logging.state';
import { LoggingModule } from './modules/admin/logging/logging.module';
import { ToolTimeSettingsModule } from './modules/admin/tool-time-settings/tool-time-settings.module';
import { TriggerableEntitiesState } from './modules/admin/triggerable-entities-with-authz/triggerable-entities.state';
import { TriggerableEntitiesModule } from './modules/admin/triggerable-entities-with-authz/triggerable-entities.module';

registerLocaleData(localeDe, 'de');

export function initializeApp(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.Init();
  };
}

@NgModule({
  declarations: [SandboxComponent, SnackbarComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ContainerModule,
    ElectricMetersModule,
    GpioDevicessModule,
    GraphQLModule,
    HttpClientModule,
    LayoutModule,
    MatNativeDateModule,
    RelayssModule,
    ProjectAdminModule,
    ToolTimeSettingsModule,
    LoggerModule.forRoot({ level: NgxLoggerLevel.TRACE }),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.apiURL, environment.imageBackend],
        sendAccessToken: true,
      },
    }),
    PaymentsModule,
    InvoicesModule,
    MyWorkshopModule,
    MyAccountModule,
    RootModule,
    SharedModule,
    TerminalModule,
    TeamModule,
    UserModule,
    AreaModule,
    TimeTrackingModule,
    ProjectsModule,
    NgxsModule.forRoot([
      UiState,
      UserState,
      AuthState,
      PaymentState,
      TeamState,
      ContainerState,
      ElectricMeterState,
      GpioDevicesState,
      RelaysState,
      ToolTimeSettingsState,
      MyWorkshopState,
      AreaState,
      TerminalState,
      BarcodeState,
      ProjectAdminState,
      VendingMachinesState,
      LoggingState,
      TriggerableEntitiesState,
    ]),
    NgxsReduxDevtoolsPluginModule.forRoot({
      name: 'NGXS store',
      disabled: environment.production,
    }),
    MatSnackBarModule,
    FontAwesomeModule,
    BarcodeModule,
    VendingMachinesModule,
    PurchasesModule,
    LoggingModule,
    TriggerableEntitiesModule,
  ],
  providers: [
    { provide: AuthConfig, useValue: environment.authConfig },
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    {
      provide: ErrorStateMatcher,
      useClass: ShowOnDirtyErrorStateMatcher,
    },
    { provide: ValidationHandler, useClass: JwksValidationHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingStateHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenHttpInterceptor,
      multi: true,
    },
    { provide: MAT_SNACK_BAR_DATA, useValue: {} },
  ],
  bootstrap: [MainComponent],
})
export class AppModule {}
