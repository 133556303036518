import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class CreateHassWebhookNameMutation extends Mutation {
  document = gql`
    mutation createHassWebHookName(
      $name: String!
      $forTriggerableType: [TriggerableTypes!]!
    ) {
      createHassWebHookName(
        input: { name: $name, forTriggerableType: $forTriggerableType }
      ) {
        id
      }
    }
  `;
}
