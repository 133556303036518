import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ElectricMetersDetailComponent } from './components/electric-meter-detail/electric-meters-detail.component';
import { ElectricMetersOverviewComponent } from './components/electric-meters-overview/electric-meters-overview.component';

@NgModule({
  declarations: [
    ElectricMetersOverviewComponent,
    ElectricMetersDetailComponent,
  ],
  imports: [CommonModule, SharedModule],
})
export class ElectricMetersModule {}
