import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class UpdateWorkHourMutation extends Mutation {
  document = gql`
    mutation updateTimeEntry(
      $date: DateTime!
      $startTime: String
      $endTime: String
      $pauseTime: String
      $description: String
      $bookedTime: Int
      $id: ID
    ) {
      updateTimeEntry(
        where: { id: $id }
        data: {
          date: $date
          startTime: $startTime
          endTime: $endTime
          pauseTime: $pauseTime
          description: $description
          bookedTime: $bookedTime
        }
      ) {
        id
        description
        user {
          id
          username
          firstName
          lastName
        }
      }
    }
  `;
}
