import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { EntityLocation, TriggerableEntities } from '@prisma/client';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { TriggerableEntitiesState } from '../../triggerable-entities.state';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import {
  FetchEntityLocationsAction,
  FetchTriggerableEntitiesAction,
} from '../../triggerable-entities.actions';
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { DeleteTriggerableEntityMutation } from '../../mutations/delete-triggerable-entity.mutation';
import { DeleteEntityLocationMutation } from '../../mutations/delete-entity-location.mutation';
import { FormBuilder } from '@angular/forms';
import { CreateEntityLocationMutation } from '../../mutations/create-entity-location.mutation';

@Component({
  selector: 'tt-triggerable-entities-locations-overview',
  templateUrl: './triggerable-entities-locations-overview.component.html',
  styleUrls: ['./triggerable-entities-locations-overview.component.scss'],
})
export class TriggerableEntitiesLocationsOverviewComponent implements OnInit {
  displayedColumns: string[] = ['name', 'description', 'actions'];
  dataSource: MatTableDataSource<EntityLocation>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Select(TriggerableEntitiesState.entityLocations)
  entityLocations$: Observable<EntityLocation[]>;

  entityForm = this.formBuilder.group({
    name: [''],
    description: [''],
  });
  constructor(
    private logger: NGXLogger,
    private store: Store,
    public dialog: MatDialog,
    public deleteEntityLocationMutation: DeleteEntityLocationMutation,
    private createEntityLocationMutation: CreateEntityLocationMutation,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new FetchEntityLocationsAction());
    this.entityLocations$.subscribe((containers) => {
      this.dataSource = new MatTableDataSource(containers);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deleteTriggerableEntity(id) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Diese  Entity Location wirklich löschen?',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteEntityLocationMutation.mutate({ id }).subscribe(
          ({ data }) => {
            this.logger.log('got  entity location delete data', data);
            this.store.dispatch(new FetchEntityLocationsAction());
          },
          (error) => {
            error.graphQLErrors.map(({ message }, i) =>
              this.logger.error(message, i),
            );
          },
        );
      }
    });
  }

  saveEntityLocation() {
    this.createEntityLocationMutation
      .mutate({
        name: this.entityForm.value.name,
        description: this.entityForm.value.description,
      })
      .subscribe(
        ({ data }) => {
          this.logger.log('got  entity location create data', data);
          this.entityForm.reset();

          this.store.dispatch(new FetchEntityLocationsAction());
        },
        (error) => {
          error.graphQLErrors.map(({ message }, i) =>
            this.logger.error(message, i),
          );
        },
      );
  }
}
