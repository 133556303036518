import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserRoleEnum } from '../../../../../../../common/enums/user-role.enum';
import { User } from '../../../../../../../common/interfaces/prisma.binding';
import { SideNavMenuEntryInterface } from '../../../../../../../common/interfaces/side-nav-menu-entry.interface';
import { AuthState } from '../../../shared/state/auth.state';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { ToggleSidebar } from '../../../shared/actions/toggle-sidebar.action';

@Component({
  selector: 'tt-side-nav-menu',
  templateUrl: './side-nav-menu.component.html',
  styleUrls: ['./side-nav-menu.component.scss'],
})
export class SideNavMenuComponent implements OnInit {
  @Select(AuthState) authState$: Observable<User>;
  showSidebar = false;
  showAdminBar = false;
  isTeamMember = false;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));
  userEntries: SideNavMenuEntryInterface[] = [
    {
      name: 'Meine Werkstatt',
      icon: 'build',
      link: 'my-workshop',
    },
    {
      name: 'Meine Einkäufe',
      icon: 'shopping_cart',
      link: 'purchases',
    },
    // {
    //   name: 'Projekte',
    //   icon: 'project-diagram',
    //   link: '',
    // },
    {
      name: 'Rechnungen',
      icon: 'account_balance',
      link: 'invoices',
    },
    {
      name: 'Mein Account',
      icon: 'account_circle',
      link: 'my-account',
    },
    {
      name: 'Zeiterfassung',
      icon: 'access_time',
      link: 'time-tracking',
    },
    {
      name: 'Projekte',
      icon: 'assignment',
      link: 'projects',
    },
    {
      name: 'Arbeitszeit- erfassung',
      icon: 'access_time',
      link: 'work-hours',
      teamOrAdminOnly: true,
    },
    // {
    //   name: 'Zahlungen',
    //   icon: 'credit-card',
    //   link: 'payments',
    // },
    // {
    //   name: 'Auswertungen',
    //   icon: 'chart-bar',
    //   link: '',
    // },
  ];
  adminEntries: SideNavMenuEntryInterface[] = [
    {
      name: 'Benutzer',
      icon: 'account_circle',
      link: 'admin/user',
    },
    {
      name: 'Berechtigungen',
      icon: 'autorenew',
      link: 'admin/triggerable-auth',
    },
    {
      name: 'Container',
      icon: 'store',
      link: 'admin/container',
    },
    {
      name: 'Freiflächen',
      icon: 'view_array',
      link: 'admin/area',
    },
    {
      name: 'Terminals',
      icon: 'settings_cell',
      link: 'admin/terminal',
    },
    {
      name: 'Energiezähler',
      icon: 'settings_input_hdmi',
      link: 'admin/electric-meters',
    },
    {
      name: 'GPIO Devices',
      icon: 'settings_input_svideo',
      link: 'admin/gpio-devices',
    },
    {
      name: 'Relais',
      icon: 'settings_power',
      link: 'admin/relays',
    },
    {
      name: 'Teams',
      icon: 'face',
      link: 'admin/teams',
    },
    {
      name: 'Vending Machines',
      icon: 'point_of_sale',
      link: 'admin/vending-machines',
    },
    {
      name: 'Barcodes (gescannt)',
      icon: 'shopping_basket',
      link: 'admin/barcodes',
    },
    {
      name: 'Barcode Items',
      icon: 'qr_code',
      link: 'admin/barcode_items',
    },
    {
      name: 'Zeiteinträge',
      icon: 'access_time',
      link: 'time-tracking',
    },
    {
      name: 'Tool Time settings',
      icon: 'build',
      link: 'admin/settings',
    },
    {
      name: 'Triggerable Entities',
      icon: 'toggle_on',
      link: 'admin/triggerable',
    },
    {
      name: 'Triggerable Entities Locations',
      icon: 'map',
      link: 'admin/triggerable/locations',
    },
    {
      name: 'Home Assistant Webhook Names',
      icon: 'rv_hookup',
      link: 'admin/hass-webhooks',
    },
  ];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private store: Store,
  ) {
    // sort menu entries by name
    this.adminEntries.sort(this._sortMenuEntries);
    this.userEntries.sort(this._sortMenuEntries);

    this.authState$.subscribe((authData: User) => {
      this.showSidebar = !this._isObjectEmpty(authData);
      this.isTeamMember = authData.isTeam;

      if (authData.hasOwnProperty('userRoles')) {
        const hasAdminRole = () =>
          authData.userRoles.some((role) => role.name === UserRoleEnum.ADMIN);

        this.showAdminBar = hasAdminRole();
      }
      if (!this.isTeamMember && !this.showAdminBar) {
        this.userEntries = this.userEntries.filter(
          (userEntry) => !userEntry.teamOrAdminOnly,
        );
      }
    });
  }

  ngOnInit() {}

  private _sortMenuEntries(
    a: SideNavMenuEntryInterface,
    b: SideNavMenuEntryInterface,
  ) {
    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // namen müssen gleich sein
    return 0;
  }

  handleSidebarClick() {
    this.isHandset$.subscribe((isHandset) => {
      if (isHandset) {
        this.store.dispatch(new ToggleSidebar());
      }
    });
  }

  private _isObjectEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
}
