import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class CreateTerminalMutation extends Mutation {
  document = gql`
    mutation createTerminal(
      $coinCredit: Int
      $coinText: String
      $coinDeviceToControl: GPIODeviceCreateOneInput
      $name: String!
      $type: TerminalType!
      $devices: GPIODeviceCreateManyWithoutTerminalInput
      $relaysToSwitch: RelayCreateManyInput
      $hassWebHooksToTrigger: String
      $triggerableEntitiesIds: TerminalCreatetriggerableEntitiesIdsInput
    ) {
      createTerminal(
        data: {
          coinCredit: $coinCredit
          coinDeviceToControl: $coinDeviceToControl
          coinText: $coinText
          name: $name
          type: $type
          devices: $devices
          relaysToSwitch: $relaysToSwitch
          hassWebHooksToTrigger: $hassWebHooksToTrigger
          triggerableEntitiesIds: $triggerableEntitiesIds
        }
      ) {
        id
        name
      }
    }
  `;
}
