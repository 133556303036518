import { Query } from 'apollo-angular';
import { Injectable } from '@angular/core';
import {
  ToolTimeGeneralSettings,
  User,
} from '../../../../../../../common/interfaces/prisma.binding';
import gql from 'graphql-tag';

export interface Response {
  getPowerChunks: ToolTimeGeneralSettings[];
}

@Injectable({
  providedIn: 'root',
})
export class GetPowerChunksQuery extends Query<Response> {
  document = gql`
    query getPowerChunks(
      $startTimeStamp: Float!
      $endTimestamp: Float!
      $meterId: Int!
      $gpioDeviceId: String!
    ) {
      getPowerChunks(
        startTimeStamp: $startTimeStamp
        endTimestamp: $endTimestamp
        meterId: $meterId
        gpioDeviceId: $gpioDeviceId
      )
    }
  `;
}
