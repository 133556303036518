import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../../../../../common/angular/environments/environment';

@Component({
  selector: 'tt-s3-image',
  templateUrl: './s3-image.component.html',
  styleUrls: ['./s3-image.component.scss'],
})
export class S3ImageComponent implements OnInit {
  imageBlobUrl;
  isPdf = false;
  isImage = false;
  filename = 'file.pdf';
  @Input()
  alt = '';
  @Input()
  showDeleteButton = false;
  @Input()
  s3Id = '';
  @Output()
  onDelete = new EventEmitter<string>();

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.http
      .get<Blob>(`${environment.imageBackend}/image/` + this.s3Id, {
        observe: 'response',
        responseType: 'blob' as 'json',
      })
      .subscribe((image) => {
        this.createImageFromBlob(image.body, image.headers.get('x-filename'));
      });
  }

  createImageFromBlob(image: Blob, filename: string) {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        if (image.type === 'application/pdf') {
          this.isPdf = true;
          this.imageBlobUrl = this.sanitizer.bypassSecurityTrustUrl(
            reader.result.toString(),
          );
          if (filename !== '') {
            this.filename = filename;
          }
        } else if (image.type !== 'application/octet-stream') {
          this.isImage = true;
          this.imageBlobUrl = reader.result;
        }
      },
      false,
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  onDeleteClick(s3Id: string): void {
    this.http
      .delete<Blob>(`${environment.imageBackend}/image/` + s3Id, {
        observe: 'response',
        // responseType: 'blob' as 'json',
      })
      .subscribe((image) => {
        this.onDelete.emit(s3Id);
      });
  }
}
