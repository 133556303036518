import { Component, OnInit } from '@angular/core';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { Select, Store } from '@ngxs/store';
import { AuthState } from '../../../shared/state/auth.state';
import { RelayService } from '../../../admin/relays/relay.service';
import { FetchLastTenLogsAction } from '../../../admin/logging/actions/fetch-last-ten-logs.action';

import {
  LoggingState,
  LoggingUserTerminalInterface,
} from '../../../admin/logging/logging.state';

@Component({
  selector: 'tt-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  ownRelays: Observable<any>;
  @Select(AuthState.isAdmin) isAdmin$: Observable<boolean>;
  @Select(LoggingState.logs) logs$: Observable<LoggingUserTerminalInterface[]>;

  getOwnRelaysQuery = gql`
    {
      getOwnRelays {
        id
        pin
        description
        connectedGPIODevice {
          deviceID
        }
      }
    }
  `;

  constructor(
    private apollo: Apollo,
    private readonly relaysService: RelayService,
    private store: Store,
  ) {}

  ngOnInit() {
    this.isAdmin$.subscribe((isAdmin) => {
      if (isAdmin) {
        this.getRelays();
        this.store.dispatch(new FetchLastTenLogsAction());
      }
    });
  }

  async getRelays() {
    this.ownRelays = this.apollo
      .watchQuery({
        fetchPolicy: 'no-cache',
        query: this.getOwnRelaysQuery,
      })
      .valueChanges.pipe(
        map((result: any) => result.data && result.data.getOwnRelays),
      );
    this.ownRelays.subscribe((data) => {});
  }

  async toggleRelay(relay) {
    this.relaysService.testTriggerRelais(relay.id);
    console.log(relay);
  }
}
