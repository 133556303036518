import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { TriggerableEntitiesOverviewComponent } from './components/triggerable-entities-overview/triggerable-entities-overview.component';
import { TriggerableEntitiesDetailsComponent } from './components/triggerable-entities-details/triggerable-entities-details.component';
import { TriggerableEntitiesAuthorizationComponent } from './components/triggerable-entities-authorization/triggerable-entities-authorization.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { TriggerableEntitiesLocationsOverviewComponent } from './components/triggerable-entities-locations-overview/triggerable-entities-locations-overview.component';
import { HassWebhookNamesOverviewComponent } from './components/hass-webhook-names-overview/hass-webhook-names-overview.component';

@NgModule({
  imports: [CommonModule, SharedModule, MatGridListModule],
  exports: [],
  declarations: [
    TriggerableEntitiesOverviewComponent,
    TriggerableEntitiesDetailsComponent,
    TriggerableEntitiesAuthorizationComponent,
    TriggerableEntitiesLocationsOverviewComponent,
    HassWebhookNamesOverviewComponent,
  ],
})
export class TriggerableEntitiesModule {}
