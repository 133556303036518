import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bookedTime',
})
export class BookedTimePipe implements PipeTransform {
  public transform(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  }
}
