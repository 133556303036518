import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  Project,
  TimeEntry,
} from '../../../../../../common/interfaces/prisma.binding';
import { FetchProjectsAction } from './actions/fetch-projects.action';
import { AllProjectsQuery } from './queries/all-projects.query';
import { Injectable } from '@angular/core';
import { CreateTimeEntryAction } from './actions/create-time-entry.action';
import { NGXLogger } from 'ngx-logger';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { FetchTimeEntriesAction } from './actions/fetch-time-entries.action';
import { AllTimeEntriesQuery } from './queries/all-time-entries.query';
import { DeleteTimeEntryAction } from './actions/delete-time-entry.action';
import { DeleteTimeEntryMutation } from './mutations/delete-time-entry.mutation';
import { ToggleTimeEntryApprovalMutation } from './mutations/toggle-time-entry-approval.mutation';
import { ToggleTimeEntryApprovalAction } from './actions/toggle-time-entry-approval.action';
import { CreateProjectAction } from './actions/create-project.action';
import { CreateProjectMutation } from './mutations/create-project.mutation';
import { DeleteProjectAction } from './actions/delete-project.action';
import { DeleteProjectMutation } from './mutations/delete-project.mutation';
import { UpdateProjectAction } from './actions/update-project.action';
import { UpdateProjectMutation } from './mutations/update-project.mutation';
import { CreateWorkHourEntryAction } from './actions/create-work-hour-entry.action';
import { CreateWorkHoursEntryMutation } from './mutations/create-work-hours-entry.mutation';
import { CreateTimeEntryMutation } from './mutations/create-time-entry.mutation';
import { FetchWorkHourEntriesAction } from './actions/fetch-work-hour-entries.action';
import { AllWorkHourEntriesQuery } from './queries/all-work-hour-entries.query';
import { UpdateWorkHourMutation } from './mutations/update-work-hour.mutation';
import { UpdateWorkHourAction } from './actions/update-work-hour.action';

export interface ProjectStateModel {
  projects: Project[];
  timeEntries: TimeEntry[];
  workHourEntries: TimeEntry[];
}

@State<ProjectStateModel>({
  name: 'project',
  defaults: {
    projects: [],
    timeEntries: [],
    workHourEntries: [],
  },
})
@Injectable()
export class ProjectAdminState {
  constructor(
    private readonly allProjectsQuery: AllProjectsQuery,
    private readonly allTimeEntriesQuery: AllTimeEntriesQuery,
    private readonly allWorkHourEntriesQuery: AllWorkHourEntriesQuery,
    private readonly createTimeEntryMutation: CreateTimeEntryMutation,
    private readonly createWorkHourEntryMutation: CreateWorkHoursEntryMutation,
    private readonly createProjectMutation: CreateProjectMutation,
    private readonly updateProjectMutation: UpdateProjectMutation,
    private readonly deleteTimeEntryMutation: DeleteTimeEntryMutation,
    private readonly deleteProjectMutation: DeleteProjectMutation,
    private readonly toggleTimeEntryApprovalMutation: ToggleTimeEntryApprovalMutation,
    private readonly updateWorkHourMutation: UpdateWorkHourMutation,
    private readonly logger: NGXLogger,
  ) {}

  @Selector()
  static projects(state: ProjectStateModel): Project[] {
    return state.projects;
  }

  @Selector()
  static timeEntries(state: ProjectStateModel): TimeEntry[] {
    return state.timeEntries;
  }

  @Selector()
  static workHourEntries(state: ProjectStateModel): TimeEntry[] {
    return state.workHourEntries;
  }

  @Action(FetchProjectsAction)
  async getProjects(
    { patchState }: StateContext<ProjectStateModel>,
    action: FetchProjectsAction,
  ) {
    return new Promise(async (resolve, reject) => {
      this.allProjectsQuery
        .watch(
          {},
          {
            fetchPolicy: 'network-only',
          },
        )
        .valueChanges.subscribe(
          ({ data, loading }: { data: any; loading: boolean }) =>
            resolve(patchState({ projects: data.projects })),
        );
    });
  }

  @Action(FetchTimeEntriesAction)
  async getAllTimeEntries({
    patchState,
    getState,
  }: StateContext<ProjectStateModel>) {
    const state = getState();
    return new Promise(async (resolve, reject) => {
      this.allTimeEntriesQuery
        .watch(
          {},
          {
            fetchPolicy: 'network-only',
          },
        )
        .valueChanges.subscribe(
          ({ data, loading }: { data: any; loading: boolean }) =>
            resolve(
              patchState({
                timeEntries: data.timeEntries,
              }),
            ),
        );
    });
  }

  @Action(FetchWorkHourEntriesAction)
  async getAllTWorkHourEntries({
    patchState,
    getState,
  }: StateContext<ProjectStateModel>) {
    const state = getState();
    return new Promise(async (resolve, reject) => {
      this.allWorkHourEntriesQuery
        .watch(
          {},
          {
            fetchPolicy: 'network-only',
          },
        )
        .valueChanges.subscribe(
          ({ data, loading }: { data: any; loading: boolean }) =>
            resolve(
              patchState({
                workHourEntries: data.workHourEntries,
              }),
            ),
        );
    });
  }

  @Action(CreateTimeEntryAction)
  createTimeEntry(
    { patchState, getState }: StateContext<ProjectStateModel>,
    { timeEntry }: CreateTimeEntryAction,
  ) {
    return this.createTimeEntryMutation.mutate(timeEntry).pipe(
      tap(({ data }: any) => {
        this.logger.log('got create time entry', data);
        const state = getState();
        patchState({
          timeEntries: [...state.timeEntries, data.createTimeEntry],
        });
      }),
      catchError((error) => {
        error.graphQLErrors.map(({ message }, i) =>
          // proper error handling
          this.logger.error(message, i),
        );
        return throwError(error);
      }),
    );
  }

  @Action(CreateWorkHourEntryAction)
  createWorkHourEntry(
    { patchState, getState }: StateContext<ProjectStateModel>,
    { workHourEntry }: CreateWorkHourEntryAction,
  ) {
    return this.createWorkHourEntryMutation.mutate(workHourEntry).pipe(
      tap(({ data }: any) => {
        this.logger.log('got create work hour entry', data);
        const state = getState();
        // patchState({
        //   timeEntries: [...state.timeEntries, data.createTimeEntry],
        // });
      }),
      catchError((error) => {
        error.graphQLErrors.map(({ message }, i) =>
          // proper error handling
          this.logger.error(message, i),
        );
        return throwError(error);
      }),
    );
  }

  @Action(CreateProjectAction)
  createProject(
    { patchState, getState }: StateContext<ProjectStateModel>,
    { project }: CreateProjectAction,
  ) {
    return this.createProjectMutation.mutate(project).pipe(
      tap(({ data }: any) => {
        this.logger.log('got project entry', data);
        const state = getState();
        patchState({
          projects: [...state.projects, data.createProject],
        });
      }),
      catchError((error) => {
        error.graphQLErrors.map(({ message }, i) =>
          // proper error handling
          this.logger.error(message, i),
        );
        return throwError(error);
      }),
    );
  }

  @Action(DeleteTimeEntryAction)
  deleteTimeEntry(
    { patchState, getState, dispatch }: StateContext<ProjectStateModel>,
    { timeEntryId }: DeleteTimeEntryAction,
  ) {
    return this.deleteTimeEntryMutation.mutate({ id: timeEntryId }).pipe(
      tap(({ data }: any) => {
        this.logger.log('got delete time entry', data);
      }),
      catchError((error) => {
        error.graphQLErrors.map(({ message }, i) =>
          // proper error handling
          this.logger.error(message, i),
        );
        return throwError(error);
      }),
    );
  }

  @Action(DeleteProjectAction)
  deleteProject(
    { patchState, getState, dispatch }: StateContext<ProjectStateModel>,
    { projectId }: DeleteProjectAction,
  ) {
    return this.deleteProjectMutation.mutate({ id: projectId }).pipe(
      tap(({ data }: any) => {
        this.logger.log('got delete project', data);
      }),
      catchError((error) => {
        error.graphQLErrors.map(({ message }, i) =>
          // proper error handling
          this.logger.error(message, i),
        );
        return throwError(error);
      }),
    );
  }

  @Action(UpdateProjectAction)
  updateProject(
    { patchState, getState, dispatch }: StateContext<ProjectStateModel>,
    { project }: UpdateProjectAction,
  ) {
    return this.updateProjectMutation.mutate(project).pipe(
      tap(({ data }: any) => {
        this.logger.log('got update project', data);
      }),
      catchError((error) => {
        error.graphQLErrors.map(({ message }, i) =>
          // proper error handling
          this.logger.error(message, i),
        );
        return throwError(error);
      }),
    );
  }

  @Action(UpdateWorkHourAction)
  updateWorkHour(
    { patchState, getState, dispatch }: StateContext<ProjectStateModel>,
    { workHour }: UpdateWorkHourAction,
  ) {
    return this.updateWorkHourMutation.mutate(workHour).pipe(
      tap(({ data }: any) => {
        this.logger.log('got update work hour', data);
      }),
      catchError((error) => {
        error.graphQLErrors.map(({ message }, i) =>
          // proper error handling
          this.logger.error(message, i),
        );
        return throwError(error);
      }),
    );
  }

  @Action(ToggleTimeEntryApprovalAction)
  toggleTimeEntryApproval(
    { patchState, getState, dispatch }: StateContext<ProjectStateModel>,
    { id, isApproved }: ToggleTimeEntryApprovalAction,
  ) {
    return this.toggleTimeEntryApprovalMutation
      .mutate({ id: id, approval: isApproved })
      .pipe(
        tap(({ data }: any) => {
          this.logger.log('got toggle time entry approval', data);
        }),
        catchError((error) => {
          error.graphQLErrors.map(({ message }, i) =>
            // proper error handling
            this.logger.error(message, i),
          );
          return throwError(error);
        }),
      );
  }
}
