import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';

@Component({
  selector: 'tt-select-entity-widget',
  templateUrl: './select-entity-widget.component.html',
  styleUrls: ['./select-entity-widget.component.scss'],
})
export class SelectEntityWidgetComponent implements OnInit {
  @Input()
  entitiesToShow: Observable<any>;
  @Input()
  selectMultipleEntities = false;
  @Input()
  fieldToDisplay: string;
  @Input()
  initiallySelectedItems = new Observable();
  @Input()
  title = '';
  @Input()
  showClearSelectionButton = false;
  @Input()
  additionalFieldsToDisplay?: string[] = [];
  @Output()
  selectedEntities = new EventEmitter();

  displayedColumns;
  dataSource;
  selection;
  initiallySelectedItemsArray = [];

  async ngOnInit() {
    this.displayedColumns = [
      'select',
      'displayField',
      ...this.additionalFieldsToDisplay,
    ];
    this.entitiesToShow.subscribe((data) => {
      this.dataSource = new MatTableDataSource<any>(data);
      this.dataSource.filterPredicate = (sourceData: any, filter: string) =>
        !filter ||
        sourceData[this.fieldToDisplay].includes(filter.trim().toLowerCase());
      this.setInitialValues();
    });
    this.selection = new SelectionModel<any>(
      this.selectMultipleEntities,
      [],
      true,
    );
    this.selection.changed.subscribe((changedSelection) =>
      this.selectedEntities.emit(this.selection.selected),
    );
    this.initiallySelectedItems.subscribe((initSelect: []) => {
      this.initiallySelectedItemsArray = initSelect;
      this.setInitialValues();
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearSelection() {
    this.selection.clear();
  }

  setInitialValues() {
    // set initial selection
    if (this.selection !== undefined) {
      let selectionData = [];
      this.dataSource.data.forEach((dataSourceData) => {
        const temp = this.initiallySelectedItemsArray.find(
          (initData) => initData['id'] === dataSourceData.id,
        );

        if (temp !== undefined) {
          selectionData.push(dataSourceData);
        }
      });
      selectionData.forEach((row) => this.selection.select(row));
    }
  }

  getAdditionalHeader(field: string): string {
    const tempArray = field.split('.');
    return tempArray.join(' ');
  }

  getAddtionalValue(element: any, field: string): string {
    return this.resolve(field, element) || '-';
  }

  private resolve(path, obj = self, separator = '.') {
    const properties = Array.isArray(path) ? path : path.split(separator);
    return properties.reduce((prev, curr) => prev && prev[curr], obj);
  }
}
