import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';
import { TimeEntry } from '../../../../../../../common/interfaces/prisma.binding';

export interface Response {
  workHourEntries: TimeEntry[];
}

@Injectable({
  providedIn: 'root',
})
export class AllWorkHourEntriesQuery extends Query<Response> {
  document = gql`
    query allWorkHourEntries {
      workHourEntries(where: { isHourRecording: true }) {
        id
        createdAt
        bookedTime
        date
        startTime
        endTime
        pauseTime
        description
        isApproved
        project {
          projectNumber
          id
          isGlobal
        }
        user {
          id
          username
          firstName
          lastName
        }
      }
    }
  `;
}
