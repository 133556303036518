import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subject } from 'rxjs';
import {
  GPIODevice,
  GPIODeviceUpdateManyWithoutTerminalInput,
  GPIODeviceUpdateOneInput,
  Relay,
  RelayUpdateManyInput,
  Terminal,
  TerminalType,
  TerminalUpdatetriggerableEntitiesIdsInput,
} from '../../../../../../../../common/interfaces/prisma.binding';
import { TerminalState } from '../../terminal.state';
import { CreateTerminalMutation } from '../../mutations/create-terminal-mutation';
import { UpdateTerminalMutation } from '../../mutations/update-terminal-mutation';
import { GpioDevicesState } from '../../../gpio-devices/gpio-devices.state';
import { RelaysState } from '../../../relays/relays.state';
import { FetchRelaysAction } from '../../../relays/actions/fetch-relays.action';
import { FetchGpioDevicesAction } from '../../../gpio-devices/actions/fetch-gpio-devices.action';
import { FetchTerminalAction } from '../../actions/fetch-terminal.action';
import { FetchHassWebhooksAction } from '../../actions/fetch-hass-webhooks.action';
import { HassWebhook } from '../../../../../../../../common/interfaces/hassWebhook.bindings';
import { FetchTriggerableEntitiesAction } from '../../../triggerable-entities-with-authz/triggerable-entities.actions';
import { TriggerableEntitiesState } from '../../../triggerable-entities-with-authz/triggerable-entities.state';
import { TriggerableEntities } from '@prisma/client';

@Component({
  selector: 'tt-terminal-detail',
  templateUrl: './terminal-detail.component.html',
  styleUrls: ['./terminal-detail.component.scss'],
})
export class TerminalDetailComponent implements OnInit {
  editMode = false;

  currentTerminal: Terminal;
  terminalForm: UntypedFormGroup;
  @Select(GpioDevicesState.gpioDevices) gpioDevices$: Observable<GPIODevice[]>;
  @Select(RelaysState.relays) relays$: Observable<Relay[]>;
  @Select(TerminalState.terminals) terminals$: Observable<Terminal[]>;
  @Select(TerminalState.hassWebhooks) hassWebhooks$: Observable<HassWebhook[]>;
  @Select(TriggerableEntitiesState.triggerableEntities)
  triggerableEntities$: Observable<TriggerableEntities[]>;
  relays: RelayUpdateManyInput = {};
  devices: GPIODeviceUpdateManyWithoutTerminalInput = {};
  coinDevice: GPIODeviceUpdateOneInput = {};

  hassWebhooks = '';
  triggerableEntities: TerminalUpdatetriggerableEntitiesIdsInput = {};
  initiallySelectedRelays$ = new Subject<[any]>();
  initiallySelectedGpioDevices$ = new Subject<[any]>();
  initiallySelectedCoinDevice$ = new Subject<[any]>();
  initiallySelectedHassWebhooks$ = new Subject<[any]>();
  initiallySelectedTriggerableEntities$ = new Subject<[any]>();
  terminalTypes: TerminalType[] = [
    'APP',
    'DOOR',
    'DOOR_NO_PIN',
    'MACHINE',
    'BARCODE_READER',
    'COIN_MACHINE',
    'VENDING_MACHINE',
  ];
  private _id: string;

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private logger: NGXLogger,
    private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: UntypedFormBuilder,
    private createTerminalMutation: CreateTerminalMutation,
    private updateTerminalMutation: UpdateTerminalMutation,
  ) {}

  ngOnInit() {
    this.terminalForm = this.formBuilder.group({
      coinCredit: [''],
      coinDeviceToControl: [''],
      coinText: ['Gib mir Kaffee-Kredit'],
      name: ['', Validators.required],
      type: ['', Validators.required],
      relaysToSwitch: [''],
      devices: [''],
    });

    this.store
      .dispatch([
        new FetchTerminalAction(),
        new FetchRelaysAction(),
        new FetchGpioDevicesAction(),
        new FetchHassWebhooksAction(),
        new FetchTriggerableEntitiesAction(),
      ])
      .subscribe(() => {
        this.route.params.subscribe((params) => {
          if (params['id'] !== undefined) {
            this.editMode = true;
            this._id = params['id'];
            this._populateForm(this._id);
          }
        });
      });
  }

  createTerminal() {
    this.createTerminalMutation
      .mutate({
        coinCredit: parseInt(this.terminalForm.value['coinCredit'], 10),
        coinText: this.terminalForm.value['coinText'],
        coinDeviceToControl: this.coinDevice,
        name: this.terminalForm.value['name'],
        type: this.terminalForm.value['type'],
        devices: this.devices,
        relaysToSwitch: this.relays,
        hassWebHooksToTrigger: this.hassWebhooks,
        triggerableEntitiesIds: this.triggerableEntities,
      })
      .subscribe(
        ({ data }) => {
          this.logger.log('got create terminal', data);
          // refresh store
          this.store.dispatch(new FetchTerminalAction());
          this.router.navigate([
            `/admin/terminal/edit/${data['createTerminal'].id}`,
          ]);
        },
        (error) => {
          error.graphQLErrors.map(({ message }, i) =>
            // proper error handling
            this.logger.error(message, i),
          );
        },
      );
  }

  updateTerminal() {
    console.log('Fcike', this.triggerableEntities);
    this.updateTerminalMutation
      .mutate({
        id: this._id,
        name: this.terminalForm.value['name'],
        type: this.terminalForm.value['type'],
        coinCredit: parseInt(this.terminalForm.value['coinCredit'], 10),
        coinText: this.terminalForm.value['coinText'],
        coinDeviceToControl: this.coinDevice,
        devices: this.devices,
        relaysToSwitch: this.relays,
        hassWebHooksToTrigger: this.hassWebhooks,
        triggerableEntitiesIds: this.triggerableEntities,
      })
      .subscribe(
        ({ data }) => {
          this.logger.log('got update terminal', data);
          // refresh store
          this.store.dispatch(new FetchTerminalAction());
        },
        (error) => {
          error.graphQLErrors.map(({ message }, i) =>
            // proper error handling
            this.logger.error(message, i),
          );
        },
      );
  }

  onRelaySelectionChange(event) {
    if (event.length > 0) {
      this.relays = {
        connect: event.map((singleEvent) => {
          return {
            id: singleEvent.id,
          };
        }),
      };
    } else {
      // @ts-ignore
      this.relays = {
        disconnect: this.currentTerminal.relaysToSwitch.map((singleEvent) => {
          return {
            id: singleEvent.id,
          };
        }),
      };
    }
  }

  onCoinDeviceSelectionChange(event) {
    if (event.length > 0) {
      this.coinDevice = {
        connect: {
          id: event[0].id,
        },
      };
    } else {
      // @ts-ignore
      this.coinDevice = { disconnect: true };
    }
  }

  onGpioDeviceSelectionChange(event) {
    if (event.length > 0) {
      this.devices = {
        connect: event.map((singleEvent) => {
          return {
            id: singleEvent.id,
          };
        }),
      };
    } else {
      // @ts-ignore
      this.devices = {
        disconnect: this.currentTerminal.devices.map((singleEvent) => {
          return {
            id: singleEvent.id,
          };
        }),
      };
    }
  }

  onHassWebhooksSelectionChange(event) {
    this.hassWebhooks = JSON.stringify(event);
  }

  onTriggerableEntitiesSelectionChange(event) {
    this.triggerableEntities = { set: event.map((selection) => selection.id) };
  }

  private _populateForm(id: string) {
    this.terminals$.subscribe((terminals) => {
      // @ts-ignore
      this.currentTerminal = terminals.find((terminal) => terminal.id === id);

      if (this.currentTerminal) {
        if (this.currentTerminal.devices) {
          console.log(this.currentTerminal);

          this.devices = {
            connect: this.currentTerminal.devices.map((singleDevice) => {
              return {
                id: singleDevice.id,
              };
            }),
          };
          // @ts-ignore
          this.initiallySelectedGpioDevices$.next(this.currentTerminal.devices);
        }
        if (this.currentTerminal.relaysToSwitch) {
          this.relays = {
            connect: this.currentTerminal.relaysToSwitch.map(
              (relayToSwitch) => {
                return {
                  id: relayToSwitch.id,
                };
              },
            ),
          };
          this.initiallySelectedRelays$.next(
            // @ts-ignore
            this.currentTerminal.relaysToSwitch,
          );
        }

        if (this.currentTerminal.coinDeviceToControl) {
          this.initiallySelectedCoinDevice$.next([
            this.currentTerminal.coinDeviceToControl as any,
          ]);
        }
        try {
          const selectedWebHooks = JSON.parse(
            this.currentTerminal.hassWebHooksToTrigger,
          );
          this.initiallySelectedHassWebhooks$.next(selectedWebHooks);
        } catch (e) {
          console.log('no hass webhooks selected');
        }

        try {
          const ids = this.currentTerminal.triggerableEntitiesIds.map((id) => {
            return {
              id: id,
            };
          });
          this.initiallySelectedTriggerableEntities$.next(ids as [any]);
        } catch (e) {
          console.log('no TriggerableEntities selected');
        }

        this.terminalForm.patchValue({
          coinCredit: this.currentTerminal.coinCredit,
          coinText: this.currentTerminal.coinText,
          coinDeviceToControl: this.coinDevice,
          name: this.currentTerminal.name,
          type: this.currentTerminal.type,
          relayToSwitch: this.relays,
          devices: this.devices,
        });
      }
    });
  }
}
