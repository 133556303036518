import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SignOut } from '../../../shared/actions/sign-out.action';
import { ToggleSidebar } from '../../../shared/actions/toggle-sidebar.action';
import { AuthState } from '../../../shared/state/auth.state';
import { UiState } from '../../../shared/state/ui.state';

@Component({
  selector: 'tt-dash',
  templateUrl: './dash.component.html',
  styleUrls: ['./dash.component.scss'],
})
export class DashComponent implements OnInit {
  @Select(UiState.showSidebar) showSidebar$: Observable<boolean>;
  @Select(AuthState.userCredit) userCredit$: Observable<number>;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  constructor(
    private breakpointObserver: BreakpointObserver,
    private oauthService: OAuthService,
    private store: Store,
  ) {}

  /**
   * Get name from JWT Profile
   * {any}
   */
  public get name() {
    const claims: any = this.oauthService.getIdentityClaims();
    if (!claims) {
      return null;
    }
    return claims.given_name;
  }

  ngOnInit() {}

  /*
  toggle navbar
   */
  toggleSidebar() {
    this.store.dispatch(new ToggleSidebar());
  }

  navbarOpenChanged(event) {
    const sidebarState = this.store.selectSnapshot<boolean>(
      (state) => state.ui.showSidebar,
    );
    // this is something we need if the user closes the sidebar in mobile mode without clicking the button (click somewhere)
    if (!event && sidebarState) {
      this.store.dispatch(new ToggleSidebar());
    }
  }

  /**
   * Use Keycloaks Logout mechanism to logout
   */
  logout() {
    this.store.dispatch(new SignOut()).subscribe(
      (success) => {},
      (error) => {},
    );
    this.oauthService.logOut();
  }
}
