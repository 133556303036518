import { Query } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { UserRole } from '../../../../../../../common/interfaces/prisma.binding';
import gql from 'graphql-tag';

export interface Response {
  userRoles: UserRole[];
}

@Injectable({
  providedIn: 'root',
})
export class UserRolesQuery extends Query<Response> {
  document = gql`
    query userRoles {
      userRoles {
        name
      }
    }
  `;
}
