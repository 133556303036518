import { Routes } from '@angular/router';
import { VendingMachinesDetailComponent } from './components/vending-machines-detail/vending-machines-detail.component';
import { VendingMachinesOverviewComponent } from './components/vending-machines-overview/vending-machines-overview.component';

export const VENDING_MACHINES_ROUTES: Routes = [
  {
    path: 'admin/vending-machines',
    component: VendingMachinesOverviewComponent,
  },

  {
    path: 'admin/vending-machines/add',
    component: VendingMachinesDetailComponent,
  },
  {
    path: 'admin/vending-machines/edit/:id',
    component: VendingMachinesDetailComponent,
  },
];
