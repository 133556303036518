import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class UpdateBarcodeMutation extends Mutation {
  document = gql`
    mutation updateBarcodeItem(
      $alreadyBilled: Boolean
      $id: ID!
      $ean: String
      $metaData: String
    ) {
      updateBarcodeItem(
        where: { id: $id }
        data: { alreadyBilled: $alreadyBilled, ean: $ean, metaData: $metaData }
      ) {
        id
      }
    }
  `;
}
