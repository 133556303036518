import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class DeleteVendingMachineSlotsMutation extends Mutation {
  document = gql`
    mutation deleteManyVendingMachineSlots($ids: [ID!]) {
      deleteManyVendingMachineSlots(where: { id_in: $ids }) {
        count
      }
    }
  `;
}
