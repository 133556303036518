import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';
import { BarcodeItem } from '../../../../../../../common/interfaces/prisma.binding';

export interface Response {
  allBarcodes: BarcodeItem[];
}

@Injectable({
  providedIn: 'root',
})
export class UnbilledBarcodesQuery extends Query<Response> {
  document = gql`
    query allBarcodes($userId: String!) {
      barcodeItems(
        where: {
          userId: $userId
          alreadyBilled: false
          barcodeItemType_not: POWER_CONSUMPTION
        }
      ) {
        id
        alreadyBilled
        ean
        userId
        metaData
        scannedDate
        barcodeItemType
        price
        scannedTerminalId
      }
    }
  `;
}
