import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';

import gql from 'graphql-tag';
import { Container } from '../../../../../../../common/interfaces/prisma.binding';

export interface Response {
  containers: Container[];
}

@Injectable({
  providedIn: 'root',
})
export class GetContainerByKeycloakIdQuery extends Query<Response> {
  document = gql`
    query containerByKcId($keycloakID: String!) {
      containers(where: { user: { keycloakID: $keycloakID } }) {
        internalNumber
        description
        location
        team {
          id
        }
        user {
          id
        }
        electricMeter {
          address
          comment
        }
      }
    }
  `;
}
