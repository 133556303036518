import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {NgxPayPalModule} from 'ngx-paypal';
import {SharedModule} from '../../shared/shared.module';
import {AbstractSubscriptionsComponent} from './components/abstract-subscriptions/abstract-subscriptions.component';
import {AddPaymentMethodsComponent} from './components/add-payment-methods/add-payment-methods.component';
import {CardsComponent} from './components/cards/cards.component';
import {CreditComponent} from './components/credit/credit.component';
import {IbanComponent} from './components/iban/iban.component';
import {InvoiceOverviewComponent} from './components/invoice-overview/invoice-overview.component';
import {PaymentMethodBraintreeComponent} from './components/payment-method-braintree/payment-method-braintree.component';
import {PaymentMethodCreditCardComponent} from './components/payment-method-credit-card/payment-method-credit-card.component';
import {PaymentMethodPaypalComponent} from './components/payment-method-paypal/payment-method-paypal.component';
import {PaymentsComponent} from './components/payments/payments.component';
import {PaypalButtonDropInComponent} from './components/paypal-button-drop-in/paypal-button-drop-in.component';
import {PlansComponent} from './components/plans/plans.component';
import {SelectPaymentMethodComponent} from './components/select-payment-method/select-payment-method.component';
import {TopUpOverviewComponent} from './components/top-up-overview/top-up-overview.component';
import {TopUpValuesComponent} from './components/top-up-values/top-up-values.component';
import {TopUpComponent} from './components/top-up/top-up.component';
import {PaymentMethodDirective} from './directives/payment-method.directive';

@NgModule({
    declarations: [
        AbstractSubscriptionsComponent,
        AddPaymentMethodsComponent,
        CardsComponent,
        CreditComponent,
        IbanComponent,
        PaymentsComponent,
        PaymentMethodCreditCardComponent,
        PaymentMethodBraintreeComponent,
        PaymentMethodPaypalComponent,
        PaypalButtonDropInComponent,
        PlansComponent,
        SelectPaymentMethodComponent,
        TopUpComponent,
        TopUpValuesComponent,
        InvoiceOverviewComponent,
        TopUpOverviewComponent,
        PaymentMethodDirective,
    ],
    exports: [
        CreditComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        NgxPayPalModule,
    ]
})
export class PaymentsModule {
}
