import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class CreateTriggerableEntitiesAuthorizationsMutation extends Mutation {
  document = gql`
    mutation createTriggerableEntitiesAuthorizations($data: String!) {
      createTriggerableEntitiesAuthorizations(data: $data)
    }
  `;
}
