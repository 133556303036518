import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {Select, Store} from '@ngxs/store';
import {NGXLogger} from 'ngx-logger';
import {Observable, Subject} from 'rxjs';
import {Area, ElectricMeter, Team, User} from '../../../../../../../../common/interfaces/prisma.binding';
import {FetchElectricMetersAction} from '../../../electric-meters/actions/fetch-electric-meters.action';
import {ElectricMeterState} from '../../../electric-meters/electric-meter.state';
import {FetchTeamsAction} from '../../../team/actions/fetch-teams.action';
import {TeamState} from '../../../team/team.state';
import {FetchUserAction} from '../../../user/actions/fetch-user.action';
import {UserState} from '../../../user/user.state';
import {FetchAreasAction} from '../../actions/fetch-areas.action';
import {AreaState} from '../../area.state';
import {CreateAreaMutation} from '../../mutations/create-area.mutation';
import {UpdateAreaMutation} from '../../mutations/update-area.mutation';

@Component({
  selector: 'tt-area-detail',
  templateUrl: './area-detail.component.html',
  styleUrls: ['./area-detail.component.scss'],
})

export class AreaDetailComponent implements OnInit {
  @Select(TeamState.teams) team$: Observable<Team[]>;
  @Select(UserState.users) user$: Observable<User[]>;
  @Select(ElectricMeterState.electricMeters) meter$: Observable<ElectricMeter[]>;
  @Select(AreaState.areas) areas$: Observable<Area[]>;
  editMode = false;

  areas: Area[];
  currentArea: Area;
  user;
  team;
  meter;
  initiallySelectedTeam$ = new Subject<[any]>();
  initiallySelectedUser$ = new Subject<[any]>();
  initiallySelectedMeter$ = new Subject<[any]>();
  areaForm: UntypedFormGroup;
  disconnectUser = [];

  private _id: string;

  constructor(private route: ActivatedRoute,
              private store: Store,
              private logger: NGXLogger,
              private router: Router,
              private snackBar: MatSnackBar,
              private formBuilder: UntypedFormBuilder,
              private createAreaMutation: CreateAreaMutation,
              private updateAreaMutation: UpdateAreaMutation,
  ) {
  }

  ngOnInit() {
    this.areaForm = this.formBuilder.group({
      description: [''],
      squareMeter: ['', Validators.required],
      pricePerSquareMeter: ['', Validators.required],
      team: [''],
      user: [''],
      meter: [''],
    });
    this.store.dispatch([
      new FetchAreasAction(),
      new FetchElectricMetersAction(),
      new FetchUserAction(),
      new FetchTeamsAction()]).subscribe(() => {
      this.areas = this.store.selectSnapshot(AreaState.areas);

      this.route.params.subscribe(params => {
        if (params['id'] !== undefined) {
          this.editMode = true;
          this._id = params['id'];
          this._populateForm(this._id);
        }
      });
    });
  }

  createArea(createNewAfter = false) {
    this.createAreaMutation.mutate({
        description: this.areaForm.value['description'].toString(),
        squareMeter: parseFloat(this.areaForm.value['squareMeter'].toString()),
        pricePerSquareMeter: parseFloat(this.areaForm.value['pricePerSquareMeter'].toString()),
        user: this.user,
        team: this.team,
        meter: this.meter,
      })
      .subscribe(({data}) => {
        this.logger.log('got create area', data);
        // refresh store
        this.store
          .dispatch(new FetchAreasAction());
        if (createNewAfter) {
          this.router.navigate([`/admin/area/add`]);

        } else {
          this.router.navigate([`/admin/area/edit/${data['createArea'].id}`]);
        }
      }, (error) => {
        error.graphQLErrors.map(({message}, i) => (
          // proper error handling
          this.logger.error(message, i)
        ));
      });

  }

  updateArea() {
    this.updateAreaMutation.mutate({
        id: this._id,
        description: this.areaForm.value['description'].toString(),
        squareMeter: parseFloat(this.areaForm.value['squareMeter'].toString()),
        pricePerSquareMeter: parseFloat(this.areaForm.value['pricePerSquareMeter'].toString()),
        user: this.user,
        team: this.team,
        meter: this.meter,
      })
      .subscribe(({data}) => {
        this.logger.log('got update areas', data);
        // refresh store
        this.store
          .dispatch(new FetchAreasAction());
      }, (error) => {
        error.graphQLErrors.map(({message}, i) => (
          // proper error handling
          this.logger.error(message, i)
        ));
      });

  }

  onTeamSelectionChange(event) {
    if (event.length > 0) {
      this.team = {
        connect: {
          id: event[0].id,
        },
      };
    } else {
      // @ts-ignore
      this.team = {disconnect: true};
    }
  }

  onMeterChange(event) {
    if (event.length > 0) {
      this.meter = {
        connect: {
          id: event[0].id,
        },
      };
    } else {
      // @ts-ignore
      this.meter = {disconnect: true};
    }
  }

  onUserSelectionChange(event) {
    if (event.length > 0) {
      this.user = {
        connect: {
          id: event[0].id,
        },
      };
    } else {
      // @ts-ignore
      this.user = {disconnect: true};
    }
  }

  private _populateForm(id: string) {
    this.areas$.subscribe((areas) => {
      // @ts-ignore
      this.currentArea = (areas).find(area => area.id === id);
      if (this.currentArea) {
        if (this.currentArea.user) {
          this.user = {
            connect: this.currentArea.user.id,
          };
          // @ts-ignore
          this.initiallySelectedUser$.next([this.currentArea.user]);
        }
        if (this.currentArea.team) {
          this.team = {
            connect: {
              id: this.currentArea.team.id,
            },
          };

          // @ts-ignore
          this.initiallySelectedTeam$.next([this.currentArea.team]);
        }

        if (this.currentArea.meter) {
          this.meter = {
            connect: {
              id: this.currentArea.meter.id,
            },
          };

          // @ts-ignore
          this.initiallySelectedMeter$.next([this.currentArea.meter]);
        }
        this.areaForm.patchValue({
          description: this.currentArea.description,
          squareMeter: this.currentArea.squareMeter,
          pricePerSquareMeter: this.currentArea.pricePerSquareMeter,
          user: this.currentArea.user,
          team: this.currentArea.team,
          meter: this.currentArea.meter,
        });
      }
    });
  }
}
