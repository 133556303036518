import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';
import { HassWebHookName } from '@prisma/client';

export interface Response {
  hassWebHookNames: HassWebHookName[];
}

@Injectable({
  providedIn: 'root',
})
export class AllHassWebhookNames extends Query<Response> {
  document = gql`
    query hassWebHookNames {
      hassWebHookNames {
        id
        createdAt
        updatedAt
        createdBy
        name
        forTriggerableType
      }
    }
  `;
}
