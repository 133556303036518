import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { FetchBarcodeListItemsAction } from '../../actions/fetch-barcode-list-items.action';
import { BarcodeState } from '../../barcode.state';
import { Observable } from 'rxjs';
import {
  BarcodeList,
  ID_Output,
  Image,
} from '../../../../../../../../common/interfaces/prisma.binding';
import { NGXLogger } from 'ngx-logger';
import { AddBarcodeListItemMutation } from '../../mutations/add-barcode-list-item.mutation';
import { UpdateBarcodeListItemMutation } from '../../mutations/update-barcode-list-item.mutation';
import { DeleteBarcodeListItemMutation } from '../../mutations/delete-barcode-list-item.mutation';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { environment } from '../../../../../../../../common/angular/environments/environment';
import {
  createImagesFromUpload,
  DocumentImagesEnum,
  IUploadedFile,
} from '../../../user/components/user-detail/user-detail.component';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { adjustColumnWidth } from '../../../../shared/utils/adjust-worksheet-column-width';
import { coffeeProductMapping } from '../../../../../../../../common/interfaces/coffee-product.maping';

@Component({
  selector: 'tt-barcode-items',
  templateUrl: './barcode-items.component.html',
  styleUrls: ['./barcode-items.component.scss'],
})
export class BarcodeItemsComponent implements OnInit {
  private uploadedProductImages: Map<string, IUploadedFile> = new Map();

  @Select(BarcodeState.barcodeListItems) barcodeListItems$: Observable<
    BarcodeList[]
  >;
  barcodeListItems: BarcodeList[] = [];
  public documentImages = DocumentImagesEnum;
  productImageDropZoneConfig: DropzoneConfigInterface = {
    addRemoveLinks: true,
    autoReset: null,
    clickable: true,
    dictRemoveFile: 'Dokument löschen',
    errorReset: null,
    cancelReset: null,
    acceptedFiles: '.jpg,.jpeg,.png',
    createImageThumbnails: true,
    paramName: DocumentImagesEnum.PRODUCT_IMAGES,
    maxFiles: 1,
    url: `${environment.imageBackend}/image/`,
  };

  constructor(
    private store: Store,
    private logger: NGXLogger,
    private addBarcodeListItemMutation: AddBarcodeListItemMutation,
    private updateBarcodeListItemMutation: UpdateBarcodeListItemMutation,
    private deleteBarcodeListItemMutation: DeleteBarcodeListItemMutation,
  ) {}

  ngOnInit() {
    this.store.dispatch(new FetchBarcodeListItemsAction());
    this.barcodeListItems$.subscribe((barcodeListItems) => {
      this.barcodeListItems = barcodeListItems;
    });
  }

  updateList(
    id: ID_Output,
    property: string,
    event: any,
    productImageToDelete: Image = undefined,
  ) {
    const dataToUpdate: any = { id };
    let value = event?.target?.textContent;
    if (property === 'price') {
      if (typeof this.filterInt(value) === 'number') {
        value = this.filterInt(value);
      } else {
        value = 0;
      }
    }
    if (property === 'productImage') {
      if (productImageToDelete) {
        value = { delete: [{ id: productImageToDelete.id }] };
      } else {
        value = createImagesFromUpload(
          this.uploadedProductImages,
          DocumentImagesEnum.PRODUCT_IMAGES,
        );
        console.log(value);
      }
    }
    dataToUpdate[property] = value;
    this.updateBarcodeListItemMutation.mutate(dataToUpdate).subscribe(
      ({ data }) => {
        this.logger.log('got new Barcode items', data);
        // refresh store
        this.store.dispatch(new FetchBarcodeListItemsAction());
      },
      (error) => {
        error.graphQLErrors.map(({ message }, i) =>
          // proper error handling
          this.logger.error(message, i),
        );
      },
    );
  }

  updateReducedVat(id: ID_Output, property: string, event: any) {
    const dataToUpdate: any = { id };

    dataToUpdate[property] = event.checked;
    this.updateBarcodeListItemMutation.mutate(dataToUpdate).subscribe(
      ({ data }) => {
        this.logger.log('got new Barcode items', data);
        // refresh store
        this.store.dispatch(new FetchBarcodeListItemsAction());
      },
      (error) => {
        error.graphQLErrors.map(({ message }, i) =>
          // proper error handling
          this.logger.error(message, i),
        );
      },
    );
  }

  remove(id: any) {
    this.deleteBarcodeListItemMutation
      .mutate({
        id,
      })
      .subscribe(
        ({ data }) => {
          this.logger.log('remove Barcode items', data);
          // refresh store
          this.store.dispatch(new FetchBarcodeListItemsAction());
        },
        (error) => {
          error.graphQLErrors.map(({ message }, i) =>
            // proper error handling
            this.logger.error(message, i),
          );
        },
      );
  }

  add() {
    this.addBarcodeListItemMutation
      .mutate({
        ean: '',
        data: '',
        price: 0.0,
        size: '',
        internalId: '0',
        reducedVAT: false,
      })
      .subscribe(
        ({ data }) => {
          this.logger.log('got new Barcode items', data);
          // refresh store
          this.store.dispatch(new FetchBarcodeListItemsAction());
        },
        (error) => {
          error.graphQLErrors.map(({ message }, i) =>
            // proper error handling
            this.logger.error(message, i),
          );
        },
      );
  }

  changeValue(id: ID_Output, property: string, event: any) {
    // this.editField = event.target.textContent;
  }

  filterInt = (value) => {
    if (/^(\-|\+)?([0-9,.]+|Infinity)$/.test(value)) return Number(value);
    return NaN;
  };

  onUploadError(args: any): void {
    this.logger.debug('onUploadError:', args);
  }

  onRemoveFile(args: any): void {
    this.uploadedProductImages.delete(args.upload.uuid);
    console.log(this.uploadedProductImages);
    this.logger.debug('onRemoveFile:', args);
  }

  /**
   * We store the uploaded image data into a local array to use this for later user mutations
   * @param args
   * @param type
   */
  onUploadSuccess(
    args: any,
    type: DocumentImagesEnum,
    barcodeListItemId: string,
  ): void {
    this.uploadedProductImages.set(args[0].upload.uuid, {
      fileName: args[0].name,
      mimeType: args[0].type,
      documentImageType: type,
      dropZoneUUID: args[0].upload.uuid,
      s3ID: args[1][type][0].key,
    });
    this.logger.debug('onUploadSuccess:', args);
    this.updateList(barcodeListItemId, 'productImage', '');
  }

  deleteProductImage(productImage: Image, barcodeListItemId: string) {
    this.updateList(barcodeListItemId, 'productImage', '', productImage);
    console.log(productImage);
  }

  exportToExcel() {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Tool Time Products');
    let coffeProducts = workbook.addWorksheet('Tool Time Coffee Products');
    coffeProducts.addRow(['Value from Vend', 'Name']);
    for (let [key, value] of coffeeProductMapping) {
      coffeProducts.addRow([key, value]);
    }

    worksheet.addRow([
      'EAN',
      'Beschreibung',
      'Preis',
      'Größe',
      'Interne ID',
      'Reduzierte MwSt',
    ]);

    worksheet.getRow(1).font = {
      bold: true,
      size: 13,
    };

    for (const product of this.barcodeListItems) {
      let temp = [
        product.ean,
        product.data,
        product.price,
        product.size,
        product.internalId,
        product.reducedVAT,
      ];

      worksheet.addRow(temp);
    }
    adjustColumnWidth(worksheet);
    adjustColumnWidth(coffeProducts);
    //set downloadable file name
    let fname = `tool-time-products`;

    //add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
    });
  }
}
