import { Query } from 'apollo-angular';
import { Injectable } from '@angular/core';
import {
  ToolTimeGeneralSettings,
  User,
} from '../../../../../../../common/interfaces/prisma.binding';
import gql from 'graphql-tag';

export interface Response {
  allToolTimeSettings: ToolTimeGeneralSettings[];
}

@Injectable({
  providedIn: 'root',
})
export class AllToolTimeSettingsQuery extends Query<Response> {
  document = gql`
    query toolTimeGeneralSettingses {
      toolTimeGeneralSettingses {
        id
        kwhPrice
        kwhPriceSurcharge
      }
    }
  `;
}
