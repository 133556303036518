import { Action, Selector, State, StateContext } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Relay } from '../../../../../../common/interfaces/prisma.binding';
import { AuthService } from '../../shared/services/auth.service';
import { FetchRelaysAction } from './actions/fetch-relays.action';
import { AllRelaysQuery } from './queries/all-relays.query';
import { Injectable } from '@angular/core';

export interface RelaysModel {
  relays: Relay[];
}

@State<RelaysModel>({
  name: 'relays',
  defaults: {
    relays: [],
  },
})
@Injectable()
export class RelaysState {
  constructor(
    private authService: AuthService,
    private allRelaysQuery: AllRelaysQuery,
    private logger: NGXLogger,
  ) {}

  @Selector()
  static relays(state: RelaysModel) {
    return state.relays;
  }

  @Action(FetchRelaysAction)
  async getRelays(
    { patchState }: StateContext<RelaysModel>,
    action: FetchRelaysAction,
  ) {
    return new Promise(async (resolve, reject) => {
      this.allRelaysQuery
        .watch(
          {},
          {
            fetchPolicy: 'network-only',
          },
        )
        .valueChanges.subscribe(
          ({ data, loading }: { data: any; loading: boolean }) =>
            resolve(patchState({ relays: data.relays })),
        );
    });
  }
}
