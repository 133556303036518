import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class UpdateTerminalMutation extends Mutation {
  document = gql`
    mutation updateTerminal(
      $id: ID!
      $coinCredit: Int
      $coinText: String
      $coinDeviceToControl: GPIODeviceUpdateOneInput
      $name: String!
      $type: TerminalType!
      $devices: GPIODeviceUpdateManyWithoutTerminalInput
      $relaysToSwitch: RelayUpdateManyInput
      $hassWebHooksToTrigger: String
      $triggerableEntitiesIds: TerminalUpdatetriggerableEntitiesIdsInput
    ) {
      updateTerminal(
        where: { id: $id }
        data: {
          coinCredit: $coinCredit
          coinDeviceToControl: $coinDeviceToControl
          coinText: $coinText
          name: $name
          type: $type
          devices: $devices
          relaysToSwitch: $relaysToSwitch
          hassWebHooksToTrigger: $hassWebHooksToTrigger
          triggerableEntitiesIds: $triggerableEntitiesIds
        }
      ) {
        id
        name
      }
    }
  `;
}
