import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ToolTimeSettingsState } from '../../tool-time-settings.state';
import { ElectricMeterState } from '../../../electric-meters/electric-meter.state';
import {
  ElectricMeter,
  ElectricMeterManufacturer,
  GPIODevice,
} from '../../../../../../../../common/interfaces/prisma.binding';
import { FetchElectricMetersAction } from '../../../electric-meters/actions/fetch-electric-meters.action';
import { FetchElectricMeterManufacturersAction } from '../../../electric-meters/actions/fetch-electric-meter-manufacturers.action';
import { FetchGpioDevicesAction } from '../../../gpio-devices/actions/fetch-gpio-devices.action';
import { GpioDevicesState } from '../../../gpio-devices/gpio-devices.state';
import { GetPowerChunksQuery } from '../../queries/get-power-chunks.query';
import moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { HassWebhook } from '../../../../../../../../common/interfaces/hassWebhook.bindings';
import { MatSort } from '@angular/material/sort';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { adjustColumnWidth } from '../../../../shared/utils/adjust-worksheet-column-width';

export interface powerPriceCalculationInterface {
  finalPrice: number;
  pricePerKwh: number;
  date: string;
  consumption: number;
}

@Component({
  selector: 'tt-power-chunks',
  templateUrl: './power-chunks.component.html',
  styleUrls: ['./power-chunks.component.scss'],
})
export class PowerChunksComponent implements OnInit {
  @Select(ElectricMeterState.electricMeters) electricMeters$: Observable<
    ElectricMeter[]
  >;

  chunksSettingsForm = new FormGroup({
    startTimeStamp: new FormControl<Date>(new Date(), Validators.required),
    endTimeStamp: new FormControl<Date>(new Date(), Validators.required),
    meterId: new FormControl<number>(1, Validators.required),
    gpioDeviceId: new FormControl<string>('', Validators.required),
  });
  @Select(ToolTimeSettingsState.toolTimeSettings)
  electricMeterManufacturers$: Observable<ElectricMeterManufacturer[]>;
  @Select(GpioDevicesState.gpioDevices)
  gpioDevices$: Observable<GPIODevice[]>;
  sumPowerWithInitial = 0;
  sumKwhWithInitial = 0;
  modBusGpioDevices: GPIODevice[] = [];
  displayedColumns: string[] = [
    'date',
    'consumption',
    'pricePerKwh',
    'finalPrice',
  ];
  @ViewChild(MatSort) sort: MatSort;

  dataSource: MatTableDataSource<powerPriceCalculationInterface>;

  constructor(
    private store: Store,
    private logger: NGXLogger,
    private getPowerChunksQuery: GetPowerChunksQuery,
  ) {}

  ngOnInit(): void {
    this.store.dispatch([
      new FetchElectricMetersAction(),
      new FetchElectricMeterManufacturersAction(),
      new FetchGpioDevicesAction(),
    ]);
    this.gpioDevices$.subscribe((gpioDevices) => {
      this.modBusGpioDevices = gpioDevices.filter(
        (gpioDevice) => gpioDevice.hasModBusDevice,
      );
    });
  }

  getPowerCunks() {
    this.getPowerChunksQuery
      .watch(
        {
          meterId: parseInt(
            this.chunksSettingsForm.value.meterId.toString(),
            10,
          ),
          startTimeStamp: new Date(
            this.chunksSettingsForm.value.startTimeStamp,
          ).getTime(),
          endTimestamp: new Date(
            this.chunksSettingsForm.value.endTimeStamp,
          ).getTime(),
          gpioDeviceId: this.chunksSettingsForm.value.gpioDeviceId,
        },
        {
          fetchPolicy: 'network-only',
        },
      )
      .valueChanges.subscribe(
        ({ data, loading }: { data: any; loading: boolean }) => {
          this.dataSource = new MatTableDataSource(data.getPowerChunks);
          const initialValue = 0;
          this.sumPowerWithInitial = data.getPowerChunks
            .map((chunk) => chunk.finalPrice)
            .reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              initialValue,
            );
          this.sumKwhWithInitial = data.getPowerChunks
            .map((chunk) => chunk.consumption)
            .reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              initialValue,
            );

          console.log('this.sumKwhWithInitial ', this.sumKwhWithInitial);
          console.log('got data', data);
        },
      );
  }

  exportToExcel() {
    const selectedDevice = this.modBusGpioDevices.find(
      (device) =>
        device.deviceID === this.chunksSettingsForm.value.gpioDeviceId,
    );
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Power Chunks');
    worksheet.addRow([
      'Startdatum',
      new Date(this.chunksSettingsForm.value.startTimeStamp).toLocaleString(),
    ]);
    worksheet.addRow([
      'Endatum',
      new Date(this.chunksSettingsForm.value.endTimeStamp).toLocaleString(),
    ]);
    worksheet.addRow(['Modbus Device', selectedDevice.description]);
    worksheet.addRow(['Zähler', this.chunksSettingsForm.value.meterId]);
    worksheet.addRow(
      this.displayedColumns.map((column) => column.toUpperCase()),
    );
    for (let i = 1; i <= 4; i++) {
      worksheet.getRow(i).font = {
        bold: true,
        size: 13,
      };
    }
    worksheet.getRow(5).font = {
      bold: true,
      size: 16,
    };
    for (const powerChunkRow of this.dataSource.data) {
      let temp = [];
      for (let field of this.displayedColumns) {
        if (field === 'date') {
          temp.push(new Date(powerChunkRow[field]).toLocaleString());
        } else {
          temp.push(powerChunkRow[field]);
        }
      }
      worksheet.addRow(temp);
    }
    adjustColumnWidth(worksheet);
    //set downloadable file name
    let fname = `powerchunks_device-${selectedDevice.description}_meter-${this.chunksSettingsForm.value.meterId}`;

    //add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
    });
  }
}
