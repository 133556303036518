import { Action, Selector, State, StateContext } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { GPIODevice } from '../../../../../../common/interfaces/prisma.binding';
import { AuthService } from '../../shared/services/auth.service';
import { FetchGpioDevicesAction } from './actions/fetch-gpio-devices.action';
import { AllGPIODevicesQuery } from './queries/all-gpio-devices.query';
import { Injectable } from '@angular/core';

export interface GpioDevicesModel {
  gpioDevices: GPIODevice[];
}

@State<GpioDevicesModel>({
  name: 'gpioDevices',
  defaults: {
    gpioDevices: [],
  },
})
@Injectable()
export class GpioDevicesState {
  constructor(
    private authService: AuthService,
    private allGpioDevices: AllGPIODevicesQuery,
    private logger: NGXLogger,
  ) {}

  @Selector()
  static gpioDevices(state: GpioDevicesModel) {
    return state.gpioDevices;
  }

  @Action(FetchGpioDevicesAction)
  async getGPIODevices(
    { patchState }: StateContext<GpioDevicesModel>,
    action: FetchGpioDevicesAction,
  ) {
    return new Promise(async (resolve, reject) => {
      this.allGpioDevices
        .watch(
          {},
          {
            fetchPolicy: 'network-only',
          },
        )
        .valueChanges.subscribe(
          ({ data, loading }: { data: any; loading: boolean }) =>
            resolve(patchState({ gpioDevices: data.gPIODevices })),
        );
    });
  }
}
