import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TriggerableEntities } from '@prisma/client';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { TriggerableEntitiesState } from '../../triggerable-entities.state';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import { FetchTriggerableEntitiesAction } from '../../triggerable-entities.actions';
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { DeleteTriggerableEntityMutation } from '../../mutations/delete-triggerable-entity.mutation';

@Component({
  selector: 'tt-triggerable-entities-overview',
  templateUrl: './triggerable-entities-overview.component.html',
  styleUrls: ['./triggerable-entities-overview.component.scss'],
})
export class TriggerableEntitiesOverviewComponent implements OnInit {
  displayedColumns: string[] = ['name', 'location', 'actions'];
  dataSource: MatTableDataSource<TriggerableEntities>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Select(TriggerableEntitiesState.triggerableEntities) containers$: Observable<
    TriggerableEntities[]
  >;

  constructor(
    private logger: NGXLogger,
    private store: Store,
    public dialog: MatDialog,
    public deleteTriggerableEntityMutation: DeleteTriggerableEntityMutation,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new FetchTriggerableEntitiesAction());
    this.containers$.subscribe((containers) => {
      this.dataSource = new MatTableDataSource(containers);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deleteTriggerableEntity(id) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Diese  Triggerable Entity wirklich löschen?',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteTriggerableEntityMutation.mutate({ id }).subscribe(
          ({ data }) => {
            this.logger.log('got triggerable entity delete data', data);
            this.store.dispatch(new FetchTriggerableEntitiesAction());
          },
          (error) => {
            error.graphQLErrors.map(({ message }, i) =>
              this.logger.error(message, i),
            );
          },
        );
      }
    });
  }
}
