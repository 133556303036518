import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class BatchUpdateTriggerableEntitiesAuthorizationsMutation extends Mutation {
  document = gql`
    mutation batchUpdateTriggerableEntitiesAuthorizations(
      $triggerableEntityIds: [String!]
      $userIds: [String!]
      $userRoles: [UserEntityRole!]
      $overwriteRoles: Boolean
    ) {
      batchUpdateTriggerableEntitiesAuthorizations(
        input: {
          triggerableEntityIds: $triggerableEntityIds
          userIds: $userIds
          userRoles: $userRoles
          overwriteRoles: $overwriteRoles
        }
      )
    }
  `;
}
