import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class CreateWorkHoursEntryMutation extends Mutation {
  document = gql`
    mutation createWorkHoursEntry(
      $date: DateTime!
      $bookedTime: Int!
      $description: String
      $userId: ID
      $startTime: String
      $endTime: String
      $pauseTime: String
      $isHourRecording: Boolean
    ) {
      createTimeEntry(
        data: {
          date: $date
          bookedTime: $bookedTime
          description: $description
          user: { connect: { id: $userId } }
          startTime: $startTime
          endTime: $endTime
          pauseTime: $pauseTime
          isHourRecording: $isHourRecording
        }
      ) {
        id
        project {
          id
          projectNumber
        }
        description
        date
        isApproved
        isHourRecording
        startTime
        endTime
        bookedTime
        user {
          id
          username
          firstName
          lastName
        }
      }
    }
  `;
}
