import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class CreateVendingMachineMutation extends Mutation {
  document = gql`
    mutation createVendingMachine(
      $name: String!
      $terminalId: ID
      $tempSensorInternalPin: Int
      $tempSensorExternalPin: Int
      $optionalWarnPin: Int
      $slots: [VendingMachineSlotCreateInput!]
    ) {
      createVendingMachine(
        data: {
          name: $name
          tempSensorInternalPin: $tempSensorInternalPin
          tempSensorExternalPin: $tempSensorExternalPin
          optionalWarnPin: $optionalWarnPin
          terminal: { connect: { id: $terminalId } }
          slots: { create: $slots }
        }
      ) {
        id
      }
    }
  `;
}
