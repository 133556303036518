import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { HassWebHookName, TriggerableTypes } from '@prisma/client';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { TriggerableEntitiesState } from '../../triggerable-entities.state';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import { FetchHassWebhookNamesAction } from '../../triggerable-entities.actions';
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { DeleteEntityLocationMutation } from '../../mutations/delete-entity-location.mutation';
import { FormBuilder } from '@angular/forms';
import { CreateEntityLocationMutation } from '../../mutations/create-entity-location.mutation';
import { CreateHassWebhookNameMutation } from '../../mutations/create-hass-webhook-name.mutation';
import { DeleteHassWebhookNameMutation } from '../../mutations/delete-hass-webhook-name.mutation';

@Component({
  selector: 'tt-hass-webhook-names-overview',
  templateUrl: './hass-webhook-names-overview.component.html',
  styleUrls: ['./hass-webhook-names-overview.component.scss'],
})
export class HassWebhookNamesOverviewComponent implements OnInit {
  triggerableTypesArray = Object.values(TriggerableTypes);
  displayedColumns: string[] = ['name', 'for-triggerable-type', 'actions'];
  dataSource: MatTableDataSource<HassWebHookName>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Select(TriggerableEntitiesState.hassWebhookNames)
  hassWebhookNames$: Observable<HassWebHookName[]>;
  selectedType: TriggerableTypes[];

  hassWebhookNamesForm = this.formBuilder.group({
    name: [''],
    forTriggerableType: [''],
  });

  constructor(
    private logger: NGXLogger,
    private store: Store,
    public dialog: MatDialog,
    public deleteHassWebhookNameMutation: DeleteHassWebhookNameMutation,
    private createHassWebhookNameMutation: CreateHassWebhookNameMutation,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new FetchHassWebhookNamesAction());
    this.hassWebhookNames$.subscribe((containers) => {
      this.dataSource = new MatTableDataSource(containers);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deleteHassWebhookName(id) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Diesen Hass Webhook Name wirklich löschen?',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteHassWebhookNameMutation.mutate({ id }).subscribe(
          ({ data }) => {
            this.logger.log('got  hass webhook name delete data', data);
            this.store.dispatch(new FetchHassWebhookNamesAction());
          },
          (error) => {
            error.graphQLErrors.map(({ message }, i) =>
              this.logger.error(message, i),
            );
          },
        );
      }
    });
  }

  saveHassWebhookName() {
    this.createHassWebhookNameMutation
      .mutate({
        name: this.hassWebhookNamesForm.value.name,
        forTriggerableType: this.hassWebhookNamesForm.value.forTriggerableType,
      })
      .subscribe(
        ({ data }) => {
          this.logger.log('got  hass webhook name create data', data);
          this.hassWebhookNamesForm.reset();

          this.store.dispatch(new FetchHassWebhookNamesAction());
        },
        (error) => {
          error.graphQLErrors.map(({ message }, i) =>
            this.logger.error(message, i),
          );
        },
      );
  }
}
