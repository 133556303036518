import { Worksheet } from 'exceljs';

export const adjustColumnWidth = (worksheet: Worksheet) => {
  worksheet.columns.forEach((column) => {
    const lengths = column.values.map((v) => v.toString().length);
    const maxLength = Math.max(...lengths.filter((v) => typeof v === 'number'));
    column.width = maxLength;
    column.alignment = { vertical: 'middle', horizontal: 'left' };
  });
};
