import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class CreateSettingsMutation extends Mutation {
  document = gql`
    mutation createToolTimeGeneralSettings(
      $kwhPrice: Float
      $kwhPriceSurcharge: Float
    ) {
      createToolTimeGeneralSettings(
        data: { kwhPrice: $kwhPrice, kwhPriceSurcharge: $kwhPriceSurcharge }
      ) {
        id
        kwhPrice
        kwhPriceSurcharge
      }
    }
  `;
}
