import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';

import gql from 'graphql-tag';
import { MeterReading } from '../../../../../../../common/interfaces/prisma.binding';

export interface Response {
  meterReadings: MeterReading[];
}

@Injectable({
  providedIn: 'root',
})
export class GetMeterReadingsByKeycloakidQuery extends Query<Response> {
  document = gql`
    query meterReadingsByKcId($keycloakID: String!) {
      meterReadings(where: { user: { keycloakID: $keycloakID } }) {
        id
        meterReading
        entryDate
        kwhPriceOnReading
        meter {
          id
          type
          model
          totalKwh
          totalSystemPower
          address
          comment
        }
      }
    }
  `;
}
