import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class UpdateBarcodeListItemMutation extends Mutation {
  document = gql`
    mutation updateBarcodeList(
      $id: ID!
      $ean: String
      $data: String
      $price: Float
      $size: String
      $internalId: String
      $reducedVAT: Boolean
      $productImage: ImageUpdateManyInput
    ) {
      updateBarcodeList(
        where: { id: $id }
        data: {
          ean: $ean
          data: $data
          price: $price
          size: $size
          internalId: $internalId
          reducedVAT: $reducedVAT
          productImage: $productImage
        }
      ) {
        id
      }
    }
  `;
}
