import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { combineLatest, Observable } from 'rxjs';
import {
  BarcodeItem,
  Terminal,
  User,
} from '../../../../../../../../common/interfaces/prisma.binding';
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { BarcodeState } from '../../barcode.state';
import { DeleteBarcodeMutation } from '../../mutations/delete-barcode.mutation';
import { FetchBarcodesAction } from '../../actions/fetch-barcodes.action';
import { FetchUserAction } from '../../../user/actions/fetch-user.action';
import { UserState } from '../../../user/user.state';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { UpdateBarcodeMutation } from '../../mutations/update-barcode.mutation';
import { UpdateManyBarcodeItemsMutation } from '../../mutations/update-many-barcode-items.mutation';
import { BillomatService } from 'src/app/modules/shared/services/billomat.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BillomatSnackbarComponent } from '../billomat-snackbar/billomat-snackbar.component';
import { mapCoffeeProduct } from '../../../../shared/utils/map-coffee-product';
import { FetchTerminalAction } from '../../../terminal/actions/fetch-terminal.action';
import { TerminalState } from '../../../terminal/terminal.state';
import { DeleteManyBarcodeItemsMutation } from '../../mutations/delete-many-barcode-items.mutation';

@Component({
  selector: 'tt-barcodes-overview',
  templateUrl: './barcodes-overview.component.html',
  styleUrls: ['./barcodes-overview.component.scss'],
})
export class BarcodesOverviewComponent implements OnInit {
  shouldBeBilled = true;
  shouldBeDraft = true;
  displayedColumns: string[] = [
    'select',
    'ean',
    'barcodeItemType',
    'userId',
    'scannedDate',
    'scannedTerminalId',
    'metaData',
    'price',
    'isBilled',
    'actions',
  ];
  selection = new SelectionModel<BarcodeItem>(true, []);

  dataSource: MatTableDataSource<BarcodeItem>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('userSelect', { static: true }) userSelect: HTMLSelectElement;
  @Select(BarcodeState.barcodes) barcodes$: Observable<BarcodeItem[]>;
  @Select(TerminalState.terminals) terminals$: Observable<Terminal[]>;
  @Select(UserState.users) users$: Observable<User[]>;
  public searchForm: UntypedFormGroup;

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'x') {
      this.nextUserEntry();
    }
    if (event.key === 'y') {
      this.previousUserEntry();
    }
  }
  selectedUserId = '';
  fullText = '';
  startDate;
  endDate;
  filteredSales = 0;
  filteredSalesCount = 0;
  hideTeamMembers = true;
  isAllSelect = false;
  users: User[] = [];
  terminals: Terminal[] = [];
  mapCoffeeProduct = mapCoffeeProduct;
  powerShouldGetCalculated = true;

  constructor(
    private store: Store,
    public dialog: MatDialog,
    private logger: NGXLogger,
    private deleteBarcodeMutation: DeleteBarcodeMutation,
    private updateBarcodeMutation: UpdateBarcodeMutation,
    private updateManyBarcodeItemsMutation: UpdateManyBarcodeItemsMutation,
    private deleteManyBarcodeItemsMutation: DeleteManyBarcodeItemsMutation,
    private billomatService: BillomatService,
    private _snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.searchFormInit();

    this.store.dispatch([
      new FetchUserAction(),
      new FetchBarcodesAction(),
      new FetchTerminalAction(),
    ]);
    combineLatest([this.users$, this.barcodes$, this.terminals$]).subscribe(
      ([users, barcodes, terminals]) => {
        this.users = users;
        this.terminals = terminals;
        this.dataSource = new MatTableDataSource(barcodes);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = this.getFilterPredicate();
        this.applyFilter();
      },
    );
  }

  /**
   * get username from userId
   * @param userId
   */
  alterUsername(userId: string): string {
    const currentUser = this.users.find((user) => user.id === userId);
    if (currentUser) {
      return `${currentUser.firstName} ${currentUser.lastName} (Username: ${currentUser.username})`;
    }
    return userId;
  }

  searchFormInit() {
    this.searchForm = new UntypedFormGroup({
      fullText: new UntypedFormControl(''),
      startDate: new UntypedFormControl(new Date()),
      endDate: new UntypedFormControl(new Date()),
      user: new UntypedFormControl(''),
    });
  }

  getFloat(number: string) {
    return parseFloat(number);
  }

  getFilterPredicate() {
    return (row: BarcodeItem, filters: string) => {
      const filterArray = filters.split('$');
      const startDate = filterArray[0];
      const endDate = filterArray[1];
      const user = filterArray[2];
      const fullText = filterArray[3];
      const matchFilter = [];

      const scannedDate = new Date(
        row.scannedDate.toString(),
      ).toLocaleDateString('de-DE');
      const userRow = row.userId;

      const isBetween = (date, min, max) =>
        date.getTime() >= min.getTime() && date.getTime() <= max.getTime();

      const userFilter = userRow
        .toLowerCase()
        .includes(user.toLocaleLowerCase());
      const fullTextFilter =
        scannedDate.toString().includes(fullText.toLowerCase()) ||
        userRow.toString().toLowerCase().includes(fullText.toLowerCase()) ||
        row.ean.toString().toLowerCase().includes(fullText.toLowerCase()) ||
        this.mapCoffeeProduct(row.ean, row.metaData)
          .toLowerCase()
          .includes(fullText.toLowerCase());

      let dateFilter = true;
      if (startDate && endDate) {
        dateFilter = isBetween(
          new Date(row.scannedDate),
          new Date(new Date(startDate).setHours(0, 0, 0, 0)),
          new Date(new Date(endDate).setHours(23, 59, 59, 999)),
        );
      }

      matchFilter.push(userFilter);
      matchFilter.push(fullTextFilter);
      matchFilter.push(dateFilter);
      return matchFilter.every(Boolean);
    };
  }

  teamMemberFilterChange(check: any) {
    console.log(check);
    this.applyFilter(check);
  }

  applyFilter(hideTeamMembers = true) {
    const startDate = this.searchForm.get('startDate').value;
    const endDate = this.searchForm.get('endDate').value;
    const userId = this.searchForm.get('user').value;
    const fullText = this.searchForm.get('fullText').value;
    this.startDate =
      startDate === null || startDate === '' ? '' : startDate.toDateString();
    this.endDate =
      endDate === null || endDate === '' ? '' : endDate.toDateString();
    this.fullText = fullText === null ? '' : fullText;
    this.selectedUserId = userId === null || userId === '' ? '' : userId;

    const filterValue =
      this.startDate +
      '$' +
      this.endDate +
      '$' +
      this.selectedUserId +
      '$' +
      fullText;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    // if (this.dataSource.paginator) {
    //   this.dataSource.paginator.firstPage();
    // }
    let teamMembers = this.users.map((user) => user.id);
    let filteredBarcodes = this.dataSource.filteredData;
    if (hideTeamMembers) {
      teamMembers = this.users
        .filter((user) => user.isTeam === true)
        .map((user) => user.id);

      filteredBarcodes = this.dataSource.filteredData.filter(
        (barcode) => !teamMembers.includes(barcode.userId),
      );
    }
    this.filteredSales = filteredBarcodes
      .map((barcodes) => barcodes?.price)
      .reduce((reduced, next) => reduced + next, 0);

    this.filteredSalesCount = filteredBarcodes
      .map((barcodes) => barcodes?.price)
      .filter((filtered) => filtered > 0).length;
    this.selection.clear();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return (
      numSelected === numRows || this.dataSource.filteredData.length < numRows
    );
  }

  isIndeterminateSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected !== numRows && this.dataSource.data.length;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelect
      ? this.selection.clear()
      : this.dataSource.filteredData.forEach((row) =>
          this.selection.select(row),
        );
    this.isAllSelect = !this.isAllSelect;
  }

  billOnExport() {
    if (this.shouldBeBilled) {
      this.batchToggleBilled(true, this.dataSource.filteredData);
    }
  }

  batchToggleBilled(setBilled: boolean, dataToExport?: BarcodeItem[]) {
    if (!dataToExport) {
      dataToExport = this.selection.selected;
    }
    if (dataToExport.length) {
      this.updateManyBarcodeItemsMutation
        .mutate({
          id: dataToExport.map((barcodeItem) => barcodeItem.id),
          alreadyBilled: setBilled,
        })
        .subscribe(
          ({ data }) => {
            this.logger.log('got Barcode update many data', data);
            this.store.dispatch(new FetchBarcodesAction());
            this.isAllSelect = false;
          },
          (error) => {
            error.graphQLErrors.map(({ message }, i) =>
              this.logger.error(message, i),
            );
          },
        );
    }
  }

  batchToggleDelete() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Diesen markierten Barcodes wirklich löschen?',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const dataToExport = this.selection.selected;

        if (dataToExport.length) {
          this.deleteManyBarcodeItemsMutation
            .mutate({
              id: dataToExport.map((barcodeItem) => barcodeItem.id),
            })
            .subscribe(
              ({ data }) => {
                this.logger.log('got Barcode Delete many data', data);
                this.store.dispatch(new FetchBarcodesAction());
                this.isAllSelect = false;
              },
              (error) => {
                error.graphQLErrors.map(({ message }, i) =>
                  this.logger.error(message, i),
                );
              },
            );
        }
      }
    });
  }

  toggleBilled(row: BarcodeItem) {
    if (row) {
      this.updateBarcodeMutation
        .mutate({ id: row.id, alreadyBilled: !row.alreadyBilled })
        .subscribe(
          ({ data }) => {
            this.logger.log('got Barcode update data', data);
            this.store.dispatch(new FetchBarcodesAction());
          },
          (error) => {
            error.graphQLErrors.map(({ message }, i) =>
              this.logger.error(message, i),
            );
          },
        );
    }
  }

  getInvoiceButtonTitle(): string {
    if (this.selectedUserId === '') {
      return 'Billomat-Rechnung für alle ausser Team erstellen';
    }
    return 'Rechnung für gewählten Nutzer erstellen';
  }

  async createInvoice() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: `Sind Sie sicher, dass Sie ${this.getInvoiceButtonTitle()} wollen?<br />Folgende Optionen sind ausgewählt:<ul>${
        this.shouldBeDraft ? '<li>Als ENTWURF anlegen</li>' : ''
      }${this.powerShouldGetCalculated ? '<li>NUR Strom abrechnen</li>' : ''}${
        this.shouldBeBilled
          ? '<li>Als abgerechnet markieren bei Export</li>'
          : ''
      }</ul>`,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        const startDate = new Date(
          new Date(this.searchForm.get('startDate').value).setHours(0, 0, 0, 0),
        );
        const endDate = new Date(
          new Date(this.searchForm.get('endDate').value).setHours(
            23,
            59,
            59,
            999,
          ),
        );
        // single invoice
        if (this.selectedUserId !== '') {
          await this.billomatService
            .createBillomatInvoice(
              this.selectedUserId,
              startDate,
              endDate,
              this.shouldBeDraft,
              this.powerShouldGetCalculated,
            )
            .then((invoice: any) => {
              if (this.shouldBeBilled) {
                this.batchToggleBilled(true, this.dataSource.filteredData);
              }
              this._snackBar.openFromComponent(BillomatSnackbarComponent, {
                data: {
                  invoiceId: invoice.id,
                  username: this.alterUsername(this.selectedUserId),
                },
                duration: 7000,
              });
            })
            .catch((error) => console.log('Fehler', error));
        }
        // if no user is selected we du a bulk-invoice reation
        if (this.selectedUserId === '') {
          await this.billomatService
            .createBillomatInvoiceBulk(
              startDate,
              endDate,
              this.shouldBeDraft,
              this.powerShouldGetCalculated,
            )
            .then(() => {
              if (this.shouldBeBilled) {
                this.batchToggleBilled(true, this.dataSource.filteredData);
              }
            })
            .catch((error) => console.log('Fehler', error));
        }
      }
    });
  }

  async createPowerLetter() {
    const startDate = new Date(
      new Date(this.searchForm.get('startDate').value).setHours(0, 0, 0, 0),
    );
    const endDate = new Date(
      new Date(this.searchForm.get('endDate').value).setHours(23, 59, 59, 999),
    );
  }

  shouldInvoiceButtonBeEnabled(): boolean {
    if (this.selectedUserId === '') {
      return true;
    }

    const currentUser = this.users.find(
      (user) => user.id === this.selectedUserId,
    );
    if (currentUser.invoiceID === '') {
      return false;
    }
    if (!currentUser.invoiceID) {
      return false;
    }
    return !!currentUser.invoiceID;
  }

  deleteBarcode(id: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Diesen Barcode wirklich löschen?',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteBarcodeMutation.mutate({ id }).subscribe(
          ({ data }) => {
            this.logger.log('got Barcode delete data', data);
            this.store.dispatch(new FetchBarcodesAction());
          },
          (error) => {
            error.graphQLErrors.map(({ message }, i) =>
              this.logger.error(message, i),
            );
          },
        );
      }
    });
  }

  getTerminalName(terminalId: string): string {
    const currentTerminal = this.terminals.find(
      (terminal) => terminal.id === terminalId,
    );
    if (currentTerminal) {
      return currentTerminal?.name;
    }
    return '';
  }

  nextUserEntry() {
    // @ts-ignore
    const userSelect: HTMLSelectElement = this.userSelect?.nativeElement;
    if (userSelect.selectedIndex < userSelect.length - 1) {
      userSelect.selectedIndex = userSelect.selectedIndex + 1;
      userSelect.dispatchEvent(new Event('change'));
    }
  }

  previousUserEntry() {
    // @ts-ignore
    const userSelect: HTMLSelectElement = this.userSelect?.nativeElement;
    if (userSelect.selectedIndex > 0) {
      userSelect.selectedIndex = userSelect.selectedIndex - 1;
      userSelect.dispatchEvent(new Event('change'));
    }
  }
}
