import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ToolTimeSettingsComponent } from './components/tool-time-settings.component';
import { PowerChunksComponent } from './components/power-chunks/power-chunks.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  exports: [],
  declarations: [ToolTimeSettingsComponent, PowerChunksComponent],
})
export class ToolTimeSettingsModule {}
