import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';
import { ElectricMeterManufacturer } from '../../../../../../../common/interfaces/prisma.binding';

export interface Response {
  allElectricMeterManufacturer: ElectricMeterManufacturer[];
}

@Injectable({
  providedIn: 'root',
})
export class AllElectricMeterManufacturesQuery extends Query<Response> {
  document = gql`
    query electricMeterManufacturers {
      electricMeterManufacturers {
        id
        name
      }
    }
  `;
}
