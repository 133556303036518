import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import {
  GPIODevice,
  Image,
  MeterReading,
} from '../../../../../../../../common/interfaces/prisma.binding';
import { AuthState } from '../../../../shared/state/auth.state';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { BillomatDtoInterface } from '../../../../../../../../common/interfaces/billomat-dto.interface';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { BillomatService } from '../../../../shared/services/billomat.service';
import { GetPowerChunksQuery } from '../../../../admin/tool-time-settings/queries/get-power-chunks.query';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { GpioDevicesState } from '../../../../admin/gpio-devices/gpio-devices.state';
import { FetchElectricMetersAction } from '../../../../admin/electric-meters/actions/fetch-electric-meters.action';
import { FetchElectricMeterManufacturersAction } from '../../../../admin/electric-meters/actions/fetch-electric-meter-manufacturers.action';
import { FetchGpioDevicesAction } from '../../../../admin/gpio-devices/actions/fetch-gpio-devices.action';

export interface MeterMonth {
  monthStart: Date;
  monthEnd: Date;
  caption: string;
}

export interface YearMonthData {
  invoices?: Partial<Image>[];
  month: number;
  monthLocal: string;
  year: number;
}

@Component({
  selector: 'tt-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
})
export class InvoicesComponent implements OnInit, AfterViewInit {
  @Select(AuthState.userInvoices) userInvoices$: Observable<[]>;
  @Select(AuthState.billomatInvoices) billomatInvoices$: Observable<[]>;
  @Select(AuthState.meterReadings) meterReadings$: Observable<MeterReading[]>;
  @Select(GpioDevicesState.gpioDevices) gpioDevices$: Observable<GPIODevice[]>;

  invoiceID: string;
  displayedColumns: string[] = [
    'select',
    'date',
    'invoice_number',
    'title',
    'total',
    'download',
  ];
  dataSource = new MatTableDataSource<BillomatDtoInterface>();
  selection = new SelectionModel<BillomatDtoInterface>(true, []);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  accordionStep = 0;
  sumPowerWithInitial = 0;
  sumKwhWithInitial = 0;
  processedInvoiceData: YearMonthData[] = [];
  modBusGpioDevices: GPIODevice[] = [];
  meterReadingStartDate = moment('2023-04-01');
  parseInt = parseInt;

  constructor(
    private readonly store: Store,
    private readonly billomatService: BillomatService,
    private getPowerChunksQuery: GetPowerChunksQuery,
  ) {
    moment.locale('de');

    this.billomatInvoices$.subscribe((invoices) => {
      this.dataSource.data = invoices;
    });
    this.userInvoices$.subscribe((invoiceData: Partial<Image>[]) => {
      // invoiceData = this.mockdata;
      // We first iterate over all invoices to extract month and year tuples
      invoiceData.forEach((mock: Image) => {
        const dateObject: YearMonthData = {
          month: parseInt(moment(mock.createdAt).format('MM').toString(), 10),
          year: parseInt(moment(mock.createdAt).format('YYYY').toString(), 10),
          monthLocal: moment(mock.createdAt).format('MMMM').toString(),
        };
        // we check if data month/year tuple is already in the array
        if (
          this.processedInvoiceData.find(
            (insertedDates) =>
              insertedDates.year === dateObject.year &&
              insertedDates.month === dateObject.month,
          ) === undefined
        ) {
          this.processedInvoiceData.push(dateObject);
        }
      });
      // sort by year and month
      this.processedInvoiceData.sort((a, b) => {
        return b.year - a.year || b.month - a.month;
      });
      // we then iterate over the month/year tuples and find and attach the corresponding invoices
      this.processedInvoiceData.map((actualInvoices) => {
        actualInvoices.invoices = invoiceData.filter(
          (mockDate) =>
            actualInvoices.year ===
              parseInt(
                moment(mockDate.createdAt).format('YYYY').toString(),
                10,
              ) &&
            actualInvoices.month ===
              parseInt(moment(mockDate.createdAt).format('MM').toString(), 10),
        );
        // sort by day in month (newest first)
        actualInvoices.invoices.sort((a, b) => {
          return (
            parseInt(moment(b.createdAt).format('DD').toString(), 10) -
            parseInt(moment(a.createdAt).format('DD').toString(), 10)
          );
        });
        return actualInvoices;
      });
    });
  }

  ngOnInit(): void {
    this.invoiceID = this.store.selectSnapshot(AuthState.invoiceID);
    this.store.dispatch([
      new FetchElectricMetersAction(),
      new FetchElectricMeterManufacturersAction(),
      new FetchGpioDevicesAction(),
    ]);
    this.gpioDevices$.subscribe((gpioDevices) => {
      this.modBusGpioDevices = gpioDevices.filter(
        (gpioDevice) => gpioDevice.hasModBusDevice,
      );
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  setStep(index: number) {
    this.accordionStep = index;
  }

  nextStep() {
    this.accordionStep++;
  }

  prevStep() {
    this.accordionStep--;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: BillomatDtoInterface): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.id + 1
    }`;
  }

  async downloadSingleInvoice(id: string) {
    const pdfData = await this.billomatService.getInvoiceByBillomatInvoiceId(
      parseInt(id, 10),
    );
    this.downloadPdf(pdfData.base64file, pdfData.filename);
  }

  async downloadBillomatSelection() {
    const pdfData = await this.billomatService.getAllBillomatInvoices(
      this.selection.selected.map((invoice) => parseInt(invoice.id, 10)),
    );
    this.downloadZip(pdfData.base64file, pdfData.filename);
  }

  downloadPdf(base64String, fileName) {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement('a');
    link.href = source;
    link.download = `${fileName}`;
    link.click();
  }

  downloadZip(base64String, fileName) {
    const source = `data:application/zip;base64,${base64String}`;
    const link = document.createElement('a');
    link.href = source;
    link.download = `${fileName}`;
    link.click();
  }

  generateMeterMonth(): MeterMonth[] {
    const returnArray: MeterMonth[] = [];
    const endDate = moment(new Date());
    for (
      let m = moment(this.meterReadingStartDate);
      m.diff(endDate, 'days') <= 0;
      m.add(1, 'months')
    ) {
      returnArray.push({
        monthStart: m.toDate(),
        monthEnd: moment(m)
          .endOf('month')
          .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
          .toDate(),
        caption: `${moment.months(m.month())} ${m.year()}`,
      });
    }
    return returnArray;
  }

  getPowerCunks(inputData: {
    meterId: number;
    gpioDeviceId: string;
    startTimeStamp: Date;
    endTimeStamp: Date;
    dateDescription: string;
  }) {
    this.getPowerChunksQuery
      .watch(
        {
          meterId: inputData.meterId,
          startTimeStamp: inputData.startTimeStamp.getTime(),
          endTimestamp: inputData.endTimeStamp.getTime(),
          gpioDeviceId: inputData.gpioDeviceId,
        },
        {
          fetchPolicy: 'network-only',
        },
      )
      .valueChanges.subscribe(
        ({ data, loading }: { data: any; loading: boolean }) => {
          const chunkData = data.getPowerChunks;
          const initialValue = 0;
          this.sumPowerWithInitial = data.getPowerChunks
            .map((chunk) => chunk.finalPrice)
            .reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              initialValue,
            );
          this.sumKwhWithInitial = data.getPowerChunks
            .map((chunk) => chunk.consumption)
            .reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              initialValue,
            );
          console.log('this.sumKwhWithInitial ', this.sumKwhWithInitial);
          console.log('got data', data);
          this.exportToExcel({ ...inputData, chunkData });
        },
      );
  }

  exportToExcel(data: {
    meterId: number;
    gpioDeviceId: string;
    startTimeStamp: Date;
    endTimeStamp: Date;
    dateDescription: string;
    chunkData: [];
  }) {
    const selectedDevice = this.modBusGpioDevices.find(
      (device) => device.deviceID === data.gpioDeviceId,
    );
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Power Chunks');
    worksheet.addRow(['Startdatum', data.startTimeStamp.toLocaleString()]);
    worksheet.addRow(['Endatum', data.endTimeStamp.toLocaleString()]);
    worksheet.addRow(['Modbus Device', selectedDevice.description]);
    worksheet.addRow(['Zähler', data.meterId]);
    const headerColumns = [
      'Datum',
      'Verbrauch (in kWh)',
      'Preis pro kWh',
      'Preis in €',
    ];
    worksheet.addRow(headerColumns);
    for (let i = 1; i <= 4; i++) {
      worksheet.getRow(i).font = {
        bold: true,
        size: 13,
      };
    }
    worksheet.getRow(5).font = {
      bold: true,
      size: 16,
    };
    for (const powerChunkRow of data.chunkData) {
      let temp = [];
      for (let field of headerColumns) {
        if (field === 'Datum') {
          temp.push(new Date(powerChunkRow['date']).toLocaleString());
        } else if (field === 'Verbrauch (in kWh)') {
          temp.push(powerChunkRow['consumption']);
        } else if (field === 'Preis pro kWh') {
          temp.push(powerChunkRow['pricePerKwh']);
        } else if (field === 'Preis in €') {
          temp.push(powerChunkRow['finalPrice']);
        }
      }
      worksheet.addRow(temp);
    }
    this.adjustColumnWidth(worksheet);
    //set downloadable file name
    let fname = `${data.dateDescription.replace(
      ' ',
      '-',
    )}_zaehlerauswertung_device-${selectedDevice.description}_meter-${
      data.meterId
    }`;

    //add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
    });
  }

  private adjustColumnWidth(worksheet) {
    worksheet.columns.forEach((column) => {
      const lengths = column.values.map((v) => v.toString().length);
      const maxLength = Math.max(
        ...lengths.filter((v) => typeof v === 'number'),
      );
      column.width = maxLength;
      column.alignment = { vertical: 'middle', horizontal: 'left' };
    });
  }
}
