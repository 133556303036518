import {
  AfterContentInit,
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import * as moment from 'moment';
import { duration, Moment } from 'moment';
import {
  TimeEntry,
  User,
} from '../../../../../../../../common/interfaces/prisma.binding';
import { AuthState } from '../../../../shared/state/auth.state';
import { Select, Store } from '@ngxs/store';
import { CreateWorkHourEntryAction } from '../../../../admin/project-admin/actions/create-work-hour-entry.action';
import { FetchWorkHourEntriesAction } from '../../../../admin/project-admin/actions/fetch-work-hour-entries.action';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FetchUserAction } from '../../../../admin/user/actions/fetch-user.action';
import { ProjectAdminState } from '../../../../admin/project-admin/project-admin.state';
import { combineLatest, Observable } from 'rxjs';
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { DeleteTimeEntryAction } from '../../../../admin/project-admin/actions/delete-time-entry.action';
import { MatDialog } from '@angular/material/dialog';
import { UpdateWorkHourAction } from '../../../../admin/project-admin/actions/update-work-hour.action';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UserState } from '../../../../admin/user/user.state';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'tt-work-hours',
  templateUrl: './work-hours.component.html',
  styleUrls: ['./work-hours.component.css'],
})
export class WorkHoursComponent implements OnInit {
  @Select(ProjectAdminState.workHourEntries) workHourEntries$: Observable<
    TimeEntry[]
  >;
  @Select(UserState.users) users$: Observable<User[]>;
  @Select(AuthState.isAdmin) isAdmin$: Observable<boolean>;

  public searchForm: UntypedFormGroup;
  selectedUserId = '';
  fullText = '';
  startDate;
  endDate;
  filteredHours = 0;
  hideTeamMembers = true;
  isAllSelect = false;
  users: User[] = [];

  startTime: string;
  tempStartTime: string;

  endTime: string;
  tempEndTime: string;

  pauseTime = '00:00';
  tempPauseTime: string;

  workDuration: string;
  workDurationInMinutes: number;

  workDate: string = new Date().toISOString().slice(0, 10);
  tempWorkDate: string;

  description: string;
  tempDescription: string;
  editTimeEntryId: string;
  workHourEntriesDataSource: MatTableDataSource<TimeEntry>;
  workHourEntries: TimeEntry[];
  updateMode = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  isAdmin: boolean;
  displayedColumnsAdmin: string[] = [
    'date',
    'startTime',
    'endTime',
    'pauseTime',
    'bookedTime',
    'description',
    'user',
    'createdAt',
    'action',
  ];
  displayedColumns: string[] = [
    'date',
    'startTime',
    'endTime',
    'pauseTime',
    'bookedTime',
    'description',
    'action',
  ];
  private _currentUser: User;

  constructor(
    private readonly store: Store,
    public readonly dialog: MatDialog,
  ) {
    this._currentUser = this.store.selectSnapshot(
      AuthState.userDetails,
    ) as User;
  }

  ngOnInit(): void {
    this.searchFormInit();
    this.isAdmin$.subscribe((admin) => {
      this.store;
      this.isAdmin = admin;
      if (this.isAdmin) {
        this.store.dispatch(new FetchUserAction());
      }
    });
    this.store.dispatch([new FetchWorkHourEntriesAction()]);
    combineLatest([this.users$, this.workHourEntries$]).subscribe(
      ([users, workHourEntries]) => {
        this.workHourEntriesDataSource = new MatTableDataSource(
          workHourEntries,
        );
        this.workHourEntriesDataSource.paginator = this.paginator;
        this.workHourEntriesDataSource.sort = this.sort;
        this.workHourEntries = workHourEntries;
        this.users = users;
        this.workHourEntriesDataSource.filterPredicate = this.getFilterPredicate();
      },
    );
  }

  getFilterPredicate() {
    return (row: TimeEntry, filters: string) => {
      const filterArray = filters.split('$');
      const startDate = filterArray[0];
      const endDate = filterArray[1];
      const user = filterArray[2];
      const fullText = filterArray[3];
      const matchFilter = [];

      const scannedDate = new Date(row.date.toString()).toLocaleDateString(
        'de-DE',
      );
      const userRow = row.user.id;

      const isBetween = (date, min, max) =>
        date.getTime() >= min.getTime() && date.getTime() <= max.getTime();

      const userFilter = userRow
        .toLowerCase()
        .includes(user.toLocaleLowerCase());
      const fullTextFilter =
        scannedDate.toString().includes(fullText.toLowerCase()) ||
        userRow.toString().toLowerCase().includes(fullText.toLowerCase());
      let dateFilter = true;
      if (startDate && endDate) {
        dateFilter = isBetween(
          new Date(row.date),
          new Date(new Date(startDate).setHours(0, 0, 0, 0)),
          new Date(new Date(endDate).setHours(23, 59, 59, 999)),
        );
      }
      matchFilter.push(userFilter);
      matchFilter.push(fullTextFilter);
      matchFilter.push(dateFilter);
      return matchFilter.every(Boolean);
    };
  }

  searchFormInit() {
    this.searchForm = new UntypedFormGroup({
      fullText: new UntypedFormControl(''),
      startDate: new UntypedFormControl(new Date()),
      endDate: new UntypedFormControl(new Date()),
      user: new UntypedFormControl(''),
    });
  }

  timeChange() {
    if (this.startTime && this.endTime && this.pauseTime) {
      let beginningTime = this.timeFromTimeString(this.startTime);
      let endTime = this.timeFromTimeString(this.endTime);
      const pauseTime = this.timeFromTimeString(this.pauseTime);
      const pauseTimeInMinutes = pauseTime.hours() * 60 + pauseTime.minutes();
      if (beginningTime.isAfter(endTime)) {
        this.endTime = this.timeStringFromTime(beginningTime);
      } else if (endTime.isBefore(beginningTime)) {
        this.endTime = this.timeStringFromTime(beginningTime);
      }
      beginningTime = this.timeFromTimeString(this.startTime);
      endTime = this.timeFromTimeString(this.endTime);
      const workDuration = duration(endTime.diff(beginningTime));
      workDuration.subtract(pauseTimeInMinutes, 'minutes');
      this.workDurationInMinutes = workDuration.asMilliseconds() / 60000;
      this.workDuration =
        this.workDurationInMinutes >= 0
          ? this.timeStringFromTime(workDuration)
          : '0';
    }
  }

  async createWorkHourEntry() {
    const userId = this._currentUser.id;

    const workHourEntry = {
      isHourRecording: true,
      date: this.workDate,
      startTime: this.startTime,
      endTime: this.endTime,
      pauseTime: this.pauseTime,
      bookedTime: this.workDurationInMinutes,
      description: this.description,
      userId: userId,
    };
    await this.store
      .dispatch(new CreateWorkHourEntryAction(workHourEntry))
      .toPromise();
    await this.store.dispatch(new FetchWorkHourEntriesAction());
  }

  deleteBookedTime(bookedTimeId: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Diesen Zeiteintrag wirklich löschen?',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store
          .dispatch(new DeleteTimeEntryAction(bookedTimeId))
          .subscribe(() => {
            this.store.dispatch(new FetchWorkHourEntriesAction());
          });
      }
    });
  }

  editBookedTime(bookedTimeId: string) {
    const workHourEntry = this.workHourEntries.find(
      (entry) => entry.id === bookedTimeId,
    );
    if (workHourEntry) {
      this.editTimeEntryId = workHourEntry.id;
      this.tempEndTime = this.endTime;
      this.tempStartTime = this.startTime;
      this.tempPauseTime = this.pauseTime;
      this.tempDescription = this.description;
      this.tempWorkDate = this.workDate;

      this.startTime = workHourEntry.startTime;
      this.endTime = workHourEntry.endTime;
      this.pauseTime = workHourEntry.pauseTime;
      this.description = workHourEntry.description;
      console.log(workHourEntry.date);
      const splitDate = workHourEntry.date.toString().split(/\D+/);
      this.workDate = `${splitDate[0]}-${splitDate[1]}-${splitDate[2]}`;
      this.updateMode = true;
    }
  }

  async updateWorkHourEntry() {
    this.timeChange();
    console.log(this.timeFromTimeString(this.workDate));
    const workHourEntry = {
      isHourRecording: true,
      date: this.parseISOString(this.workDate).toISOString(),
      startTime: this.startTime,
      endTime: this.endTime,
      pauseTime: this.pauseTime,
      bookedTime: this.workDurationInMinutes,
      description: this.description,
      id: this.editTimeEntryId,
    };
    await this.store
      .dispatch(new UpdateWorkHourAction(workHourEntry))
      .toPromise();
    this.store.dispatch([new FetchWorkHourEntriesAction()]);
    this.updateMode = false;
    this.chancelUpdate();
    this.editTimeEntryId = undefined;
  }

  chancelUpdate() {
    this.endTime = this.tempEndTime;
    this.startTime = this.tempStartTime;
    this.pauseTime = this.tempPauseTime;
    this.description = this.tempDescription;
    this.workDate = this.tempWorkDate;
    this.editTimeEntryId = undefined;
    this.updateMode = false;
  }

  applyFilter() {
    const startDate = this.searchForm.get('startDate').value;
    const endDate = this.searchForm.get('endDate').value;
    const userId = this.searchForm.get('user').value;
    const fullText = this.searchForm.get('fullText').value;
    this.startDate =
      startDate === null || startDate === '' ? '' : startDate.toDateString();
    this.endDate =
      endDate === null || endDate === '' ? '' : endDate.toDateString();
    this.fullText = fullText === null ? '' : fullText;
    this.selectedUserId = userId === null || userId === '' ? '' : userId;

    const filterValue =
      this.startDate +
      '$' +
      this.endDate +
      '$' +
      this.selectedUserId +
      '$' +
      fullText;
    console.log(filterValue);
    this.workHourEntriesDataSource.filter = filterValue.trim().toLowerCase();
    const filteredBarcodes = this.workHourEntriesDataSource.filteredData;

    this.filteredHours = filteredBarcodes
      .map((barcodes) => barcodes?.bookedTime)
      .filter((filtered) => filtered > 0)
      .reduce((x, y) => x + y);
  }

  private timeFromTimeString(timeString: string): Moment {
    return moment({
      h: parseInt(timeString.split(':')[0], 10),
      m: parseInt(timeString.split(':')[1], 10),
    });
  }

  private timeStringFromTime(time: any): string {
    return `${time
      .hours()
      .toString()
      .padStart(2, '0')}:${time.minutes().toString().padStart(2, '0')}`;
  }

  private parseISOString(s) {
    const b = s.split(/\D+/);
    return new Date(Date.UTC(b[0], --b[1], b[2]));
  }

  private getTimeFromMins(mins: number) {
    // do not include the first validation check if you want, for example,
    // getTimeFromMins(1530) to equal getTimeFromMins(90) (i.e. mins rollover)
    if (mins >= 24 * 60 || mins < 0) {
      throw new RangeError(
        'Valid input should be greater than or equal to 0 and less than 1440.',
      );
    }
    const h = (mins / 60) | 0,
      m = mins % 60 | 0;
    return moment.utc().hours(h).minutes(m).format('hh:mm');
  }
}
