import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class CreateEntityLocationMutation extends Mutation {
  document = gql`
    mutation createEntityLocation($name: String!, $description: String) {
      createEntityLocation(input: { name: $name, description: $description }) {
        id
      }
    }
  `;
}
