export const coffeeProductMapping: Map<string, string> = new Map([
  ['coffee-product-1', 'Kaffee creme'],
  ['coffee-product-2', 'Heißwasser'],
  ['coffee-product-3', 'Cappuccino'],
  ['coffee-product-4', 'Latte Macchiato'],
  ['coffee-product-5', 'Cappuccino plus'],
  ['coffee-product-6', 'Schokomilch'],
  ['coffee-product-7', 'Espresso'],
  ['coffee-product-8', 'Schoko herb'],
  ['coffee-product-9', 'Milchkaffee'],
  ['coffee-product-10', 'Espresso Macchiato'],
  ['coffee-product-11', 'Schokokaffee'],
  ['coffee-product-25', 'Kaffee Creme XL'],
  ['coffee-product-26', 'Heisswasser XL'],
  ['coffee-product-27', 'Cappuccino XL'],
  ['coffee-product-28', 'Latte Macchiato XL'],
  ['coffee-product-29', 'Cappuccino Plus XL'],
  ['coffee-product-30', 'Schokomlich XL'],
  ['coffee-product-31', 'Espresso XL'],
  ['coffee-product-32', 'Schoko herb XL'],
  ['coffee-product-33', 'Milchkaffee XL'],
  ['coffee-product-35', 'Schokokaffee XL'],
]);
