import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';
import { VendingMachine } from '../../../../../../../common/interfaces/prisma.binding';

export interface Response {
  vendingMachines: VendingMachine[];
}

@Injectable({
  providedIn: 'root',
})
export class AllVendingMachinesQuery extends Query<Response> {
  document = gql`
    query vendingMachines {
      vendingMachines {
        id
        name
        optionalWarnPin
        tempSensorInternalPin
        tempSensorExternalPin
        terminal {
          id
          relaysToSwitch {
            id
            pin
            description
          }
          name
        }
        slots {
          id
          ammountOfProducts
          product {
            id
            data
            ean
            price
            size
          }
          relay {
            description
            id
          }
          slotPosition
          internalIsEmptyPin
          disabled
        }
      }
    }
  `;
}
