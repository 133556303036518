import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class DeleteEntityLocationMutation extends Mutation {
  document = gql`
    mutation deleteEntityLocation($id: String!) {
      deleteEntityLocation(id: $id)
    }
  `;
}
