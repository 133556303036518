import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { VendingMachine } from '../../../../../../../../common/interfaces/prisma.binding';
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { VendingMachinesState } from '../../vending-machines.state';
import { DeleteVendingMachineMutation } from '../../mutations/delete-vending-machine.mutation';
import { FetchVendingMachinesAction } from '../../actions/fetch-vending-machines.action';

@Component({
  selector: 'tt-vending-machines-overview',
  templateUrl: './vending-machines-overview.component.html',
  styleUrls: ['./vending-machines-overview.component.scss'],
})
export class VendingMachinesOverviewComponent implements OnInit {
  displayedColumns: string[] = ['name', 'actions'];
  dataSource: MatTableDataSource<VendingMachine>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Select(VendingMachinesState.vendingMachines) vendingMachines: Observable<
    VendingMachine[]
  >;

  constructor(
    private store: Store,
    public dialog: MatDialog,
    private logger: NGXLogger,
    private deleteVendingMachineMutation: DeleteVendingMachineMutation,
  ) {}

  ngOnInit() {
    this.store.dispatch(new FetchVendingMachinesAction()).subscribe(() => {
      this.vendingMachines.subscribe((vendingMachines) => {
        this.dataSource = new MatTableDataSource(vendingMachines);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deleteVendingMachine(id: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Diesen Automaten wirklich löschen?',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteVendingMachineMutation.mutate({ id }).subscribe(
          ({ data }) => {
            this.logger.log('got Vending Machine delete data', data);
            this.store.dispatch(new FetchVendingMachinesAction());
          },
          (error) => {
            error.graphQLErrors.map(({ message }, i) =>
              this.logger.error(message, i),
            );
          },
        );
      }
    });
  }
}
