import { Component, OnInit } from '@angular/core';
import { AuthState } from '../../../../shared/state/auth.state';
import {
  BarcodeItem,
  User,
} from '../../../../../../../../common/interfaces/prisma.binding';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { BarcodeState } from '../../../../admin/barcode/barcode.state';
import { FetchOwnUnbilledBarcodesAction } from '../../../../admin/barcode/actions/fetch-own-unbilled-barcodes.action';
import { mapCoffeeProduct } from 'src/app/modules/shared/utils/map-coffee-product';

@Component({
  selector: 'tt-purchases',
  templateUrl: './purchases.component.html',
  styleUrls: ['./purchases.component.css'],
})
export class PurchasesComponent implements OnInit {
  currentUser: User;
  barcodes: BarcodeItem[];
  mapCoffeeProduct = mapCoffeeProduct;
  @Select(BarcodeState.barcodes) billedBarcode$: Observable<BarcodeItem[]>;
  displayedColumns: string[] = ['date', 'item', 'cost'];

  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    this.currentUser = this.store.selectSnapshot(AuthState.userDetails) as User;
    this.store.dispatch(
      new FetchOwnUnbilledBarcodesAction(this.currentUser.id),
    );
    this.billedBarcode$.subscribe(
      (billedBarcodes) => (this.barcodes = billedBarcodes),
    );
  }

  getTotalCost() {
    return this.barcodes
      .map((t) => t.price)
      .reduce((acc, value) => acc + value, 0);
  }
}
