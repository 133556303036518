import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';
import { EntityLocation } from '@prisma/client';

export interface Response {
  entityLocations: EntityLocation[];
}

@Injectable({
  providedIn: 'root',
})
export class AllEntityLocations extends Query<Response> {
  document = gql`
    query entityLocations {
      entityLocations {
        id
        internalId
        createdAt
        updatedAt
        createdBy
        name
        description
      }
    }
  `;
}
