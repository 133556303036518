import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class UpdateUserMutation extends Mutation {
  document = gql`
    mutation updateUser(
      $id: ID!
      $firstName: String!
      $lastName: String!
      $username: String!
      $street: String!
      $zip: String!
      $email: String!
      $city: String!
      $housenumber: String!
      $birthday: DateTime
      $userRoles: UserRoleUpdateManyInput!
      $pin: String
      $rfidID: String
      $rfidNumber: Int
      $phone: String
      $givenKeys: String
      $cameOver: String
      $notes: String
      $company: String
      $currency: String
      $invoiceID: String
      $invoiceIDPrivate: String
      $documents: ImageUpdateManyInput
      $idCardImages: ImageUpdateManyInput
      $liabilityInsuranceImages: ImageUpdateManyInput
      $container: ContainerUpdateManyWithoutUserInput
      $invoices: ImageUpdateManyInput
      $meterMeterReading: MeterReadingUpdateManyWithoutUserInput
      $isTeam: Boolean
    ) {
      updateUser(
        where: { id: $id }
        data: {
          firstName: $firstName
          lastName: $lastName
          username: $username
          street: $street
          zip: $zip
          email: $email
          city: $city
          housenumber: $housenumber
          invoiceID: $invoiceID
          invoiceIDPrivate: $invoiceIDPrivate
          birthday: $birthday
          userRoles: $userRoles
          pin: $pin
          rfidID: $rfidID
          rfidNumber: $rfidNumber
          phone: $phone
          givenKeys: $givenKeys
          cameOver: $cameOver
          notes: $notes
          company: $company
          currency: $currency
          documents: $documents
          idCardImages: $idCardImages
          liabilityInsuranceImages: $liabilityInsuranceImages
          container: $container
          invoices: $invoices
          meterMeterReading: $meterMeterReading
          isTeam: $isTeam
        }
      ) {
        id
      }
    }
  `;
}
