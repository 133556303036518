import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';
import { Relay } from '../../../../../../../common/interfaces/prisma.binding';

export interface Response {
  allRelays: Relay[];
}

@Injectable({
  providedIn: 'root',
})
export class AllRelaysQuery extends Query<Response> {
  document = gql`
    query allRelays {
      relays {
        id
        description
        pin
        autoReleaseTimeInMs
        autoRelease
        autoToggle
        connectedGPIODevice {
          description
          id
        }
        team {
          id
          name
        }
        users {
          id
          lastName
          firstName
          username
        }
        gpioController
        isStoppedByPin
        isStoppedByPinNumber
        isStoppedByPinController
        isStoppedByPinHigh
      }
    }
  `;
}
