import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ProjectsComponent } from './components/projects/projects.component';

@NgModule({
    declarations: [ProjectsComponent],
    exports: [],
    imports: [CommonModule, SharedModule]
})
export class ProjectsModule {}
