import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { ElectricMeter } from '../../../../../../../../common/interfaces/prisma.binding';
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { FetchElectricMetersAction } from '../../actions/fetch-electric-meters.action';
import { ElectricMeterState } from '../../electric-meter.state';
import { DeleteElectricMeterMutation } from '../../mutations/delete-electric-meter.mutation';
import { AllElectricMetersQuery } from '../../queries/all-electric-meters.query';
import { FetchGpioDevicesAction } from '../../../gpio-devices/actions/fetch-gpio-devices.action';

@Component({
  selector: 'tt-electric-meters-overview',
  templateUrl: './electric-meters-overview.component.html',
  styleUrls: ['./electric-meters-overview.component.scss'],
})
export class ElectricMetersOverviewComponent implements OnInit {
  displayedColumns: string[] = [
    'address',
    'model',
    'totalkwh',
    'gpioDevice',
    'comment',
    'actions',
  ];
  dataSource: MatTableDataSource<ElectricMeter>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Select(ElectricMeterState.electricMeters) electricMeters: Observable<
    ElectricMeter[]
  >;

  constructor(
    private allElectricMetersQuery: AllElectricMetersQuery,
    private deleteElectricMeterMutation: DeleteElectricMeterMutation,
    private store: Store,
    public dialog: MatDialog,
    private logger: NGXLogger,
  ) {}

  ngOnInit() {
    this.store.dispatch([
      new FetchElectricMetersAction(),
      new FetchGpioDevicesAction(),
    ]);
    this.electricMeters.subscribe((electricMeters) => {
      this.dataSource = new MatTableDataSource(electricMeters);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  getFloat(number: string) {
    return parseFloat(number);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deleteMeter(id: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Diesen Zähler wirklich löschen?',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteElectricMeterMutation.mutate({ id }).subscribe(
          ({ data }) => {
            this.logger.log('got Electric Meter delete data', data);
            this.store.dispatch(new FetchElectricMetersAction());
          },
          (error) => {
            error.graphQLErrors.map(({ message }, i) =>
              this.logger.error(message, i),
            );
          },
        );
      }
    });
  }
}
