import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class AddBarcodeListItemMutation extends Mutation {
  document = gql`
    mutation createBarcodeList(
      $ean: String!
      $data: String!
      $price: Float!
      $size: String!
      $internalId: String!
      $reducedVAT: Boolean!
    ) {
      createBarcodeList(
        data: {
          ean: $ean
          data: $data
          price: $price
          size: $size
          internalId: $internalId
          reducedVAT: $reducedVAT
        }
      ) {
        id
      }
    }
  `;
}
