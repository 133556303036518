import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Select } from '@ngxs/store';
import { Subscription } from 'apollo-client/util/Observable';
import { Observable } from 'rxjs';
import { StripeInvoice } from '../../../../../../../../common/interfaces/stripe.bindings';
import { PaymentState } from '../../payment.state';

@Component({
  selector: 'tt-invoice-overview',
  templateUrl: './invoice-overview.component.html',
  styleUrls: ['./invoice-overview.component.scss'],
})
export class InvoiceOverviewComponent implements OnInit {
  electricMeters: Subscription;
  displayedColumns: string[] = [
    'number',
    'startDate',
    'endDate',
    'subscriptionName',
    'invoiceLink',
  ];
  dataSource: MatTableDataSource<StripeInvoice>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Select(PaymentState.subscriptionInvoices) invoices$: Observable<any>;

  constructor() {}

  ngOnInit() {
    this.invoices$.subscribe((data) => {
      console.log(data);
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
