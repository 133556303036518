import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';
import { User } from '../../../../../../../common/interfaces/prisma.binding';

export interface Response {
  allUsers: User[];
}

@Injectable({
  providedIn: 'root',
})
export class GetSingleUserQuery extends Query<Response> {
  document = gql`
    query user($id: ID!) {
      user(where: { id: $id }) {
        account {
          id
        }
        active
        birthday
        braintreeID
        cameOver
        city
        company
        container {
          id
        }
        credit
        currency
        documents {
          s3key
          mimetype
          fileName
        }
        email
        firstName
        givenKeys
        housenumber
        invoiceID
        invoiceIDPrivate
        idCardImages {
          s3key
          mimetype
          fileName
        }
        id
        invoices {
          s3key
          mimetype
          fileName
        }
        isPro
        isTeam
        keycloakID
        language
        lastName
        liabilityInsuranceImages {
          s3key
          mimetype
          fileName
        }
        meterMeterReading {
          id
          meterReading
          entryDate
          meter {
            id
            address
          }
        }
        minuteCredit
        notes
        phone
        pin
        rfidID
        rfidNumber
        street
        stripeID
        team {
          id
        }
        username
        userRoles {
          name
        }
        zip
      }
    }
  `;
}
