import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Terminal } from '../../../../../../common/interfaces/prisma.binding';
import { FetchTerminalAction } from './actions/fetch-terminal.action';
import { AllTerminalQuery } from './queries/all-terminal.query';
import { Injectable } from '@angular/core';
import { HassWebhook } from '../../../../../../common/interfaces/hassWebhook.bindings';
import { FetchHassWebhooksAction } from './actions/fetch-hass-webhooks.action';
import { AllHassWebhooks } from './queries/all-hass-webhooks.query';

export interface TerminalStateModel {
  terminals: Terminal[];
  hassWebhooks: HassWebhook[];
}

@State<TerminalStateModel>({
  name: 'terminals',
  defaults: {
    terminals: [],
    hassWebhooks: [],
  },
})
@Injectable()
export class TerminalState {
  constructor(
    private allTerminalQuery: AllTerminalQuery,
    private allHassWebhooks: AllHassWebhooks,
  ) {}

  @Selector()
  static terminals(state: TerminalStateModel) {
    return state.terminals;
  }
  @Selector()
  static hassWebhooks(state: TerminalStateModel) {
    return state.hassWebhooks;
  }
  @Action(FetchTerminalAction)
  async getContainers({ patchState }: StateContext<TerminalStateModel>) {
    return new Promise(async (resolve, reject) => {
      this.allTerminalQuery
        .watch(
          {},
          {
            fetchPolicy: 'network-only',
          },
        )
        .valueChanges.subscribe(
          ({ data, loading }: { data: any; loading: boolean }) =>
            resolve(patchState({ terminals: data.terminals })),
        );
    });
  }

  @Action(FetchHassWebhooksAction)
  async getHassWebhooks({ patchState }: StateContext<TerminalStateModel>) {
    return new Promise(async (resolve, reject) => {
      this.allHassWebhooks
        .watch(
          {},
          {
            fetchPolicy: 'network-only',
          },
        )
        .valueChanges.subscribe(
          ({ data, loading }: { data: any; loading: boolean }) =>
            resolve(patchState({ hassWebhooks: data.getHassWebhooks })),
        );
    });
  }
}
