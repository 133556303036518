import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';
import {
  EntityLocation,
  TriggerableEntitiesAuthorizations,
} from '@prisma/client';

export interface Response {
  entityLocations: TriggerableEntitiesAuthorizations[];
}

@Injectable({
  providedIn: 'root',
})
export class AllTriggerableEntitiesAuthorizations extends Query<Response> {
  document = gql`
    query triggerableEntitiesAuthorizations {
      triggerableEntitiesAuthorizations {
        id
        createdBy
        createdAt
        userId
        userRoles
        triggerableEntityId
        triggerableEntity {
          id
          name
        }
      }
    }
  `;
}
